import React, { useState } from 'react';
import { useIntl } from 'umi';
import { Input, Select, Space, Button, Modal, Radio, message } from 'antd';
import { ArrowsAltOutlined } from '@ant-design/icons';

import { PostTrainingProgramSuggestion } from 'services/suggestion';

const { Option } = Select;
const { TextArea } = Input;

const SubmitCom = ({ programInfo }) => {
  const intl = useIntl();

  const { id: trainingProgramId, title } = programInfo || {};

  const [inputData, setInputData] = useState('');
  const [optionValue, setOptionValue] = useState('Comments');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const selectBefore = (
    // <Select value={optionValue} onChange={(value) => setOptionValue(value)}>
    //   <Option value="Comments">
    //     {intl.formatMessage({ id: 'gameComments' })}
    //   </Option>
    //   <Option value="Gamification">
    //     {intl.formatMessage({ id: 'gameGamification' })}
    //   </Option>
    // </Select>
    <Radio.Group
      onChange={(e) => setOptionValue(e.target.value)}
      value={optionValue}
    >
      <Radio value="Comments">
        {intl.formatMessage({ id: 'gameComments' })}
      </Radio>
      <Radio value="Gamification">
        {intl.formatMessage({ id: 'gameGamification' })}
      </Radio>
    </Radio.Group>
  );

  const onExpend = (e) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    if (!inputData) return;

    setLoading(true);

    const params = {
      trainingProgramId,
      content: inputData,
      type: optionValue,
    };

    const res = await PostTrainingProgramSuggestion(params);

    if (res.response.ok) {
      setIsModalOpen(false);
      message.success(intl.formatMessage({ id: 'check the latest progress' }));
      setInputData('');
    } else {
      const {
        data: { error },
      } = res;
      message.error(error.message);
    }

    setLoading(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div style={{ marginTop: '18px' }}>
      <Space>
        <Input
          addonBefore={selectBefore}
          value={inputData}
          style={{ width: '625px' }}
          onChange={(e) => setInputData(e.target.value)}
          onPressEnter={handleOk}
          placeholder={intl.formatMessage({
            id: 'Suggestions for training activity',
          })}
          suffix={
            <ArrowsAltOutlined
              onClick={onExpend}
              style={{ fontSize: '16px', cursor: 'pointer' }}
            />
          }
        />
        <Button loading={loading} onClick={handleOk}>
          {intl.formatMessage({ id: 'submitOther' })}
        </Button>
      </Space>
      <Modal
        width={600}
        title={intl.formatMessage({ id: 'Suggestions title' }, { title })}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          <Space>
            <div>{intl.formatMessage({ id: 'type' })}: </div>
            <Radio.Group
              onChange={(e) => setOptionValue(e.target.value)}
              value={optionValue}
            >
              <Radio value="Comments">
                {intl.formatMessage({ id: 'gameComments' })}
              </Radio>
              <Radio value="Gamification">
                {intl.formatMessage({ id: 'gameGamification' })}
              </Radio>
            </Radio.Group>
          </Space>

          <TextArea
            rows={4}
            value={inputData}
            placeholder={intl.formatMessage({
              id: 'Suggestions for training activity',
            })}
            onChange={(e) => setInputData(e.target.value)}
          />
        </Space>
      </Modal>
    </div>
  );
};

export default SubmitCom;
