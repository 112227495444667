import React, { useState, useEffect } from 'react';
import { showErrorMsg } from 'cognitiveleap-core-us/utils/utils';
import { message, Modal, Spin } from 'antd';
import { useIntl } from 'umi';
import { UpdateChild } from 'services/group';
import { GetCenterSubjectList } from 'services/userManager';

import TableTransfer from 'components/TableTransfer';

const ManageChildModal = (props) => {
  const { visible, currentRecord, institutionType, reloadPage, closeModal } =
    props;

  const intl = useIntl();

  const [transferList, setTransferList] = useState([]); // 管理儿童 穿梭框数据
  const [targetKeys, setTargetKeys] = useState([]); // 管理儿童 穿梭框已选择儿童的id数组

  const [loading, setLoading] = useState({
    selectChildLoading: false,
    trainSpinLoading: false,
  });

  const getData = async () => {
    setLoading({ ...loading, trainSpinLoading: true });
    const childListRes = await GetCenterSubjectList({
      skipCount: 0,
      maxResultCount: 999,
      // ageStage: currentRecord.ageStage,
      status: 'Bind',
    });
    if (childListRes.response.ok) {
      let tempData = [];
      // 过滤掉已注销的儿童数据
      childListRes.data.items
        .filter((item) => item.name !== '***')
        .forEach((item) => {
          tempData.push({
            key: item.id.toString(),
            outpatientNumber:
              institutionType === 'Medical' ? item.outpatientNumber : null,
            name: item.name,
          });
        });
      // 将已添加的儿童添加到穿梭框右边
      if (currentRecord.groupLinkUsers.length > 0) {
        const keyList = currentRecord.groupLinkUsers.map((user) => user.id);
        setTargetKeys(keyList);
      }
      setTransferList(tempData);
    } else {
      showErrorMsg(childListRes, intl);
    }
    setLoading({ ...loading, trainSpinLoading: false });
  };

  useEffect(() => {
    getData();
  }, []);

  const leftTableColumns =
    institutionType === 'Medical'
      ? [
          {
            dataIndex: 'outpatientNumber',
            title: `${intl.formatMessage({ id: 'clinicNumber'})}`,
          },
          {
            dataIndex: 'name',
            title: `${intl.formatMessage({ id: 'subjectName'})}`,
          },
        ]
      : [
          {
            dataIndex: 'name',
            title: `${intl.formatMessage({ id: 'subjectName'})}`,
          },
        ];
  const rightTableColumns = leftTableColumns;

  return (
    <Modal
      width={900}
      destroyOnClose={true}
      title={intl.formatMessage({ id: 'manageChildren'})}
      open={visible}
      cancelText={`${intl.formatMessage({ id: 'later'})}`}
      onOk={async () => {
        setLoading({
          ...loading,
          selectChildLoading: true,
        });
        const res = await UpdateChild({
          groupId: currentRecord.id,
          userIds: targetKeys,
        });
        if (res.response.ok) {
          reloadPage();
        } else {
          showErrorMsg(res, intl);
        }
        closeModal();
        setTargetKeys([]);
        setLoading({
          ...loading,
          selectChildLoading: false,
        });
      }}
      onCancel={() => {
        closeModal();
        setTargetKeys([]);
      }}
      confirmLoading={loading.selectChildLoading}
    >
      <Spin spinning={loading.trainSpinLoading}>
        <TableTransfer
          dataSource={transferList}
          targetKeys={targetKeys}
          disabled={false}
          showSearch={true}
          onChange={(keyList) => {
            const maxNum =
              currentRecord.type === 'OneToOne' ? 1 : currentRecord.capacity;
            if (keyList.length > maxNum) {
              message.warning(intl.formatMessage({ id: 'manageTips'}, { num: currentRecord.type === 'OneToOne' ? 1 : currentRecord.capacity }));
              return;
            }
            setTargetKeys(keyList);
          }}
          filterOption={(inputValue, item) =>
            (item.name && item.name.includes(inputValue)) ||
            (item.outpatientNumber &&
              item.outpatientNumber.includes(inputValue))
          }
          leftColumns={leftTableColumns}
          rightColumns={rightTableColumns}
          description={{
            leftDes: intl.formatMessage({ id: 'manageDes'}),
            rightDes: intl.formatMessage({ id: 'manageTips' }, { num: currentRecord.type === 'OneToOne' ? 1 : currentRecord.capacity })
          }}
        />
      </Spin>
    </Modal>
  );
};

export default ManageChildModal;
