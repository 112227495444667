import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal';
import { useIntl } from 'umi';

import { matchDynamicForm } from 'cognitiveleap-core-us/utils/utils';

import {
  PostRechargeUserContract,
  PutRechargeUserContract,
} from 'services/userContract';

import moment from 'moment';

const PaymentModal = ({
  userContractId,
  visible = false,
  currentRecordItem = {},
  closeModal = () => {},
  onReloadPage = () => {},
}) => {
  const intl = useIntl();

  const contentMenu = {
    Properties: [
      {
        EditorType: 'Dropdown',
        ShowTitle: intl.formatMessage({ id: 'Payment Type' }),
        Value: 'add',
        Setting: {
          DropdownOptions: [
            {
              Value: intl.formatMessage({ id: 'Payment' }),
              Id: 'add',
            },
            {
              Value: intl.formatMessage({ id: 'Refund' }),
              Id: 'sub',
            },
          ],
          Required: true,
        },
        FormKey: 'isRecharge',
      },
      {
        EditorType: 'NumberInput',
        ShowTitle: intl.formatMessage({ id: 'PayAmount' }),
        FormKey: 'amount',
        AdditionalData: null,
        Value: 1,
        Setting: {
          Required: true,
          Props: {
            min: 1,
            step: 1,
          },
        },
        Description: '',
      },
      {
        EditorType: 'DatePicker',
        ShowTitle: intl.formatMessage({ id: 'Payment Date' }),
        FormKey: 'payDate',
        AdditionalData: null,
        Value: null,
        Setting: {
          Required: true,
          DateType: 'date',
          format: 'MM/DD/YYYY',
          DisabledDate: (current) => {
            return current && current > moment();
          },
        },
      },
      {
        EditorType: 'Input',
        ShowTitle: intl.formatMessage({ id: 'Comments' }),
        FormKey: 'remark',
        AdditionalData: null,
        Value: null,
        Setting: {
          Required: false,
        },
        Description: '',
      },
    ],
  };

  const [modalContent, setModalContent] = useState(contentMenu);
  const [loading, setLoading] = useState({
    modalLoading: false,
  });

  useEffect(() => {
    if (Object.keys(currentRecordItem).length) {
      const { amount } = currentRecordItem;
      let tempContent = { ...modalContent };

      const _currentRecordItem = {
        ...currentRecordItem,
        isRecharge: amount >= 0 ? 'add' : 'sub',
        amount: Math.abs(amount),
      };

      const editForm = matchDynamicForm(tempContent, _currentRecordItem);

      setModalContent(editForm);
    } else {
      setModalContent(contentMenu);
    }
  }, [currentRecordItem]);

  const modalProps = {
    title: intl.formatMessage({ id: 'PaymentButton' }),
    visible,
    content: modalContent,
    loading: loading.modalLoading,
    onCancel: closeModal,
    async onOk(value) {
      const { isRecharge } = value;
      setLoading({
        ...loading,
        modalLoading: true,
      });

      const isEdit = Object.keys(currentRecordItem).length > 0;

      const params = {
        ...value,
        isRecharge: isRecharge === 'add',
        userContractId,
      };

      const res = isEdit
        ? await PutRechargeUserContract({
            ...params,
            id: currentRecordItem.id,
          })
        : await PostRechargeUserContract(params);

      if (res.response.ok) {
        onReloadPage();
        closeModal();
      } else {
        const { error } = res.data;
        message.error(error.message);
      }

      setLoading({
        ...loading,
        modalLoading: false,
      });
    },
  };

  return <MagicFormModal {...modalProps} />;
};

export default PaymentModal;
