import getRequest from 'utils/request';

const GetUserListApi = '/api/rocketAdmin/userContract/userList';
const UserContractApi = '/api/rocketAdmin/userContract';
const PostStopUserContractApi = '/api/rocketAdmin/userContract/stop';
const PostReEnableUserContractApi = '/api/rocketAdmin/userContract/reEnable';
const RechargeUserContractApi = '/api/rocketAdmin/userContract/recharge';
const GetReasonQuestionApi = '/api/rocketAdmin/userContract/reasonQuestion';
const GetKeyIndicatorsApi =
  '/api/rocketAdmin/userContract/keyIndicators/{userId}';

export async function GetReasonQuestion(data) {
  return getRequest()(GetReasonQuestionApi, {
    method: 'GET',
    params: data,
  });
}

export async function GetKeyIndicators(data) {
  return getRequest()(GetKeyIndicatorsApi.replace('{userId}', data.userId), {
    method: 'GET',
    params: data,
  });
}

export async function PostStopUserContract(data) {
  return getRequest()(PostStopUserContractApi, {
    method: 'POST',
    data,
  });
}

export async function PostReEnableUserContract(data) {
  return getRequest()(PostReEnableUserContractApi, {
    method: 'POST',
    data,
  });
}

export async function PutRechargeUserContract(data) {
  return getRequest()(RechargeUserContractApi, {
    method: 'PUT',
    data,
  });
}

export async function PostRechargeUserContract(data) {
  return getRequest()(RechargeUserContractApi, {
    method: 'POST',
    data,
  });
}

export async function GetUserContractDetail(data) {
  return getRequest()(UserContractApi, {
    method: 'GET',
    params: data,
  });
}

export async function PostUserContract(data) {
  return getRequest()(UserContractApi, {
    method: 'POST',
    data,
  });
}

export async function PutUserContract(data) {
  return getRequest()(UserContractApi, {
    method: 'PUT',
    data,
  });
}

export async function GetUserList(data) {
  return getRequest()(GetUserListApi, {
    method: 'GET',
    params: data,
  });
}
