import { message, Modal, notification } from 'antd'
import { getDvaApp } from 'umi';
import {
  GETMusicProgress,
  PostMusicProgress,
  UpdateMusicProgress,
  GetCurrentMusicIndex,
  GetCurrentPlanMusicRecord,
  GetCourseProgress,
  GetUserCourseProcess,
  PostGroupMusicProgress,
  PutAsyncTrack,
  GetTrialPlanMusic,
  PutTrialPlanProgress,
} from 'services/music'
import { IntlManager } from 'utils/helper';

import { convertDuration, fixedZero, showMusicStatusMsg, releaseWakeLock, requestWakeLock } from 'utils/utils'
import { musicRecordIntervalTime } from 'utils/config'
import { convertTimeToSecond } from 'cognitiveleap-core-us/utils/utils'

import localForage from 'localforage'
import storageKeys from 'utils/storageKeys'

const fromObj = {
  '/system/center/detail/subjectDetail': 'subjectOfflinePlan/updateCurrentlist',
  '/system/center/detail/userOfflinePlanDetail': 'userOfflinePlanDetail/updateCurrentlist',
}

const hasGlobalMusicPlayerPathName = [
  '/system/center/detail/subjectDetail',
  '/system/center/detail/userOfflinePlanDetail',
  '/system/center/detail/userOfflineCourseDetail',
  '/system/center/detail/groupOfflinePlanDetail',
  '/system/center/detail/groupClassDetail'
]

export function isEqualPlan(newPlanInfo, oldPlanInfo) {
  if (!oldPlanInfo) return false

  const newPlanId = newPlanInfo.userOfflineTrainingPlan && newPlanInfo.userOfflineTrainingPlan.id
  const oldPlanId = oldPlanInfo.userOfflineTrainingPlan && oldPlanInfo.userOfflineTrainingPlan.id

  const newCourseNum = newPlanInfo.num
  const oldCourseNum = oldPlanInfo.num

  const newCourseCode = newPlanInfo.iLs && newPlanInfo.iLs.code
  const oldCourseCode = oldPlanInfo.iLs && oldPlanInfo.iLs.code

  return newPlanId == oldPlanId && newCourseNum == oldCourseNum && newCourseCode == oldCourseCode
}

const musicPlayer = {
  namespace: 'musicplayer',

  state: {
    showGlobalMusicplayer: false,
    musicPlayerDrawerVisible: false,
    subjectInfo: null,
    planInfo: null,
    pathName: '',
    canPlay: true, // 音乐播放次数超过三次设置为false
    currentIndex: 0, // 当前音乐的下标
    currentTime: 0, // 当前音乐播放到的位置
    isOpen: false, // 是否在播放中
    from: '', // 从哪个详情页进来
    duration: 0, // 音乐总时长
    testMusicModalVisible: false, // 测试音乐的弹框显示
    userCourseNum: 1, // 一对多播放音乐显示专用的
    bluetoothCheckFlag : false, // 是否展示蓝牙连接弹窗
    myMusicBlobList: [],
    cacheLoading: false,
    statusLoading: false
  },

  effects: {
    *openMusicPlayerDrawer({ payload: { planInfo, from, subjectInfo } }, { call, put, select }) {
      // localForage.clear()
      const intl = IntlManager.MyIntl()

      const { currentUser } = yield select(state => state.user)
      const { current } = currentUser || {}
      const { roles = [] } = current || {}
      const isIndependentCoach = roles.some(role => role.name === 'IndependentCoach')

      const {
        planInfo: oldPlanInfo,
        isOpen: oldIsOpen,
        currentTime: oldCurrentTime,
        currentIndex: oldCurrentIndex,
      } = yield select((state) => state.musicplayer)

      const tracks = planInfo.iLs && planInfo.iLs.tracks
      if (oldPlanInfo && oldIsOpen) {
        yield put({
          type: 'changeMusicStatus',
          payload: { status: false, needSubmitProgress: false, canRequest: false },
        })
        yield put({
          type: 'submitMusicProgress',
          payload: {
            oldPlanInfo,
            oldCurrentTime,
            oldCurrentIndex,
          },
        })
      }

      const UserId = subjectInfo.id
      const UserOfflinePlanId = planInfo.userOfflineTrainingPlan.id
      const Num = parseInt(planInfo.num)
      const prePlanInfo = yield localForage.getItem(`prePlanInfo${UserId}`)
      let currentPlanMusicRecord = { isUpdateData: false }
      let currentIndex = 0
      let trackCode = null
      const params = {
        UserId,
        UserOfflinePlanId,
        Num,
        Platform: 'Web',
      }

      localForage.removeItem(`prePlanInfo${UserId}`)
      localForage.removeItem(`currentPlanMusicRecord${UserId}`)

      if (!isEqualPlan(planInfo, prePlanInfo)) {
        // 前端造个假数据用于缓存
        const localData = tracks.map(item => {
          return {
            lastProgress: "00:00:00",
            num: Num,
            trackCode: item.code,
            duration: item.duration,
            userOfflinePlanId: UserOfflinePlanId
          }
        })

        currentPlanMusicRecord.data = localData
        yield localForage.setItem(`currentPlanMusicRecord${UserId}`, currentPlanMusicRecord)
      }

      let isOverPlay = false
      const res = yield call(GetCurrentMusicIndex, params)

      if (res.response.ok) {
        const { trackCode: _trackCode, canListen, cannotListenReason, isFinished } = res.data

        if (!canListen && isFinished) {
          Modal.warning({
            title: cannotListenReason,
            content: ''
          })
          isOverPlay = true
        }

        trackCode = _trackCode
        currentIndex = tracks.findIndex((item) => item.code == trackCode)
      }

      yield put({
        type: 'updateState',
        payload: {
          from,
          musicPlayerDrawerVisible: true,
          currentIndex,
          planInfo,
          subjectInfo,
          canPlay: !isOverPlay,
          myMusicBlobList: []
        },
      })
      yield put({ type: 'getMusicProgress' })

      // 保存本地缓存音乐
      if (oldPlanInfo) {
        yield localForage.setItem(`prePlanInfo${UserId}`, oldPlanInfo)
      } else {
        yield localForage.setItem(`prePlanInfo${UserId}`, planInfo)
      }

      notification.destroy()
    },

    *openTrialSessionMusicPlayerDrawer({ payload: { planInfo, from, subjectInfo, autoPlay = false } }, { call, put, select }) {
      const {
        planInfo: oldPlanInfo,
        isOpen: oldIsOpen,
        currentTime: oldCurrentTime,
        currentIndex: oldCurrentIndex,
      } = yield select((state) => state.musicplayer)

      const { id: trialPlanId } = planInfo || {};
      const { id: UserId } = subjectInfo || {};

      if (oldPlanInfo && oldIsOpen) {
        yield put({
          type: 'changeMusicStatus',
          payload: { status: false, needSubmitProgress: false, canRequest: false },
        })
        yield put({
          type: 'submitMusicProgress',
          payload: {
            oldPlanInfo,
            oldCurrentTime,
            oldCurrentIndex,
          },
        })
      }

      const prePlanInfo = yield localForage.getItem(`prePlanInfo${UserId}`)

      let currentPlanMusicRecord = { isUpdateData: false }
      let currentIndex = 0
      let trackCode = null
      let tracks = []

      localForage.removeItem(`prePlanInfo${UserId}`)
      localForage.removeItem(`currentPlanMusicRecord${UserId}`)

      const res = yield call(GetTrialPlanMusic, { trialPlanId })

      if (res.response.ok) {
        const { curTrack, tracks: _tracks } = res.data
        const { code: _trackCode  } = curTrack || {};

        trackCode = _trackCode
        tracks = _tracks
        currentIndex = _tracks.findIndex((item) => item.code == trackCode)

        // if (!isEqualPlan(planInfo, prePlanInfo)) {
          // 前端造个假数据用于缓存
          const localData = tracks.map(item => {
            return {
              lastProgress: "00:00:00",
              trackCode: item.code,
              duration: item.duration,
            }
          })

          currentPlanMusicRecord.data = localData
          yield localForage.setItem(`currentPlanMusicRecord${UserId}`, currentPlanMusicRecord)
        // }
      }


      yield put({
        type: 'updateState',
        payload: {
          from,
          musicPlayerDrawerVisible: true,
          currentIndex,
          planInfo: {
            ...planInfo,
            iLs: res.data || {},
          },
          subjectInfo,
          myMusicBlobList: []
        },
      })

      yield put.resolve({ type: 'getMusicProgress' })

      // 保存本地缓存音乐
      if (oldPlanInfo) {
        yield localForage.setItem(`prePlanInfo${UserId}`, oldPlanInfo)
      } else {
        yield localForage.setItem(`prePlanInfo${UserId}`, planInfo)
      }

      if (autoPlay) {
        yield put({ type: 'changeMusicStatus', payload: { status: true } })
      }

      notification.destroy()
    },

    *openGroupMusicPlayerDrawer({ payload: { data, planInfo, from } }, { call, put, select }) {
      const intl = IntlManager.MyIntl()

      const { currentUser } = yield select(state => state.user)
      const { current } = currentUser || {}
      const { roles = [] } = current || {}
      const isCoach = roles.some(role => role.name === 'Coach')

      const {
        planInfo: oldPlanInfo,
        isOpen: oldIsOpen,
        currentTime: oldCurrentTime,
        currentIndex: oldCurrentIndex,
      } = yield select((state) => state.musicplayer)

      if (oldPlanInfo && oldIsOpen) {
        yield put({
          type: 'changeMusicStatus',
          payload: { status: false, needSubmitProgress: false, canRequest: false },
        })
        yield put({
          type: 'submitMusicProgress',
          payload: {
            audioOperate: 'Stop',
            oldPlanInfo,
            oldCurrentTime,
            oldCurrentIndex,
          },
        })
      }

      let currentIndex = 0
      const { subjectInfoReturnModel, userMusics } = data
      const { id: UserId } = subjectInfoReturnModel
      const { id: GroupTrainingPlanId, currentNum } = planInfo

      const params = {
        UserId,
        GroupTrainingPlanId,
        Num: currentNum,
        Platform: 'Web'
      }

      const res = yield call(GetUserCourseProcess, params)
      let tempPlanInfo;
      let isOverPlay = false

      if (res.response.ok) {
        const { trackCode, tracks = [], canListen, cannotListenReason, isFinished } = res.data

        if (!canListen && isFinished) {
          Modal.warning({
            title: cannotListenReason,
            content: ''
          })
          isOverPlay = true
        }

        currentIndex = tracks.findIndex((item) => item.trackCode == trackCode)

        const prePlanInfo = yield localForage.getItem(`prePlanInfo${UserId}`)
        let currentPlanMusicRecord = { isUpdateData: false }

        if (!isEqualPlan(planInfo, prePlanInfo)) {
          // 前端造个假数据用于缓存
          const localData = tracks.map(item => {
            const { trackCode, duration } = item
            return {
              lastProgress: "00:00:00",
              num: parseInt(currentNum),
              trackCode: trackCode,
              duration: duration,
              userOfflinePlanId: GroupTrainingPlanId
            }
          })

          currentPlanMusicRecord.data = localData
          yield localForage.setItem(`currentPlanMusicRecord${UserId}`, currentPlanMusicRecord)
        }

        tempPlanInfo = {
          ...planInfo,
          num: currentNum,
          iLs: {
            tracks: tracks.map(track => {
              const { trackCode, courseCode } = track
              return {
                ...track,
                courseCode,
                code: trackCode
              }
            })
          }
        }
      }

      yield put({
        type: 'updateState',
        payload: {
          from,
          musicPlayerDrawerVisible: true,
          currentIndex,
          userCourseNum: fixedZero(userMusics[0].musicCourseNum),
          planInfo: tempPlanInfo,
          subjectInfo: subjectInfoReturnModel,
          canPlay: !isOverPlay,
          myMusicBlobList: []
        },
      })

      yield put({ type: 'getMusicProgress' })

      // 保存本地缓存音乐
      if (oldPlanInfo) {
        yield localForage.setItem(`prePlanInfo${UserId}`, oldPlanInfo)
      } else {
        yield localForage.setItem(`prePlanInfo${UserId}`, tempPlanInfo)
      }
    },

    // 修改音乐状态(开始 \ 暂停)
    *changeMusicStatus({ payload: { status, currentIndex, needSubmitProgress = true, canRequest = true } }, { call, put, select }) {
      const intl = IntlManager.intl;
      yield put({ type: 'updateState', payload: { statusLoading: true } });

      const { currentIndex: oldCurrentIndex, planInfo, subjectInfo, from, canPlay } = yield select((state) => state.musicplayer)
      const userId = subjectInfo.id

      if (!canPlay) {
        notification.destroy()
        yield put({ type: 'updateState', payload: { statusLoading: false } })
        return
      }

      if (status) {
        notification.destroy()

        const tracks = planInfo.iLs && planInfo.iLs.tracks
        const TrackCodeIndex = currentIndex !== undefined ? currentIndex : oldCurrentIndex
        const TrackCode = tracks && tracks[TrackCodeIndex] && tracks[TrackCodeIndex].code
        // 本地保存音乐进度
        if (window[`localSaveMusicRecordIntervel${userId}`]) {
          clearInterval(window[`localSaveMusicRecordIntervel${userId}`])
        }

        window[`localSaveMusicRecordIntervel${userId}`] = setInterval(async () => {
          const time = getDvaApp()._store.getState().musicplayer.currentTime
          const localRecord = await localForage.getItem(`currentPlanMusicRecord${userId}`)
          localRecord && localRecord.data.forEach(item => {
            if (item.trackCode === TrackCode) {
              item.lastProgress = convertDuration(time)
            }
          })
          const updateData = localRecord && localRecord.data.find(item => item.trackCode === TrackCode)
          const { trackCode, num, lastProgress, userOfflinePlanId, duration } = updateData || {}

          // 进度为0不提交
          if (convertTimeToSecond(lastProgress)) {
            if (from === 'groupClassDetail') {
              const params = {
                userId,
                trackCode,
                progress: lastProgress,
                groupTrainingPlanId: userOfflinePlanId,
                num,
                platform: "Web"
              }
              PutAsyncTrack(params)
            } else if (from === 'trialSessionPlan') {
              const { id: trialPlanId } = planInfo || {}
              const trialParams = {
                trialPlanId,
                trackCode,
                progress: lastProgress,
                platform: 'Web',
              }

              await PutTrialPlanProgress(trialParams)
            } else {
              const params = {
                userId,
                trackCode,
                progress: lastProgress,
                userOfflinePlanId,
                num,
                platform: "Web"
              }
              const res = await UpdateMusicProgress(params)
              if (res.response.ok) {
                const { canListen, cannotListenReason } = res.data || {}
                if (!canListen) {
                  Modal.warning({
                    title: cannotListenReason || '本课音乐已收听结束',
                    content: '',
                  });

                  getDvaApp()._store.dispatch({
                    type: 'musicplayer/closeGlobalMusicPlayer'
                  });

                  setTimeout(() => {
                    notification.destroy()
                  }, 1000);

                  getDvaApp()._store.dispatch({
                    type: 'subjectOfflinePlan/loadMusicProgress',
                    payload: {
                      UserOfflinePlanId: userOfflinePlanId,
                      UserId: userId,
                      Num: num
                    }
                  });
                }
              }
            }
          }

          await localForage.setItem(`currentPlanMusicRecord${userId}`, localRecord)
        }, musicRecordIntervalTime * 1000)

        yield put({ type: 'updateState', payload: { isOpen: status } })

        if (currentIndex != undefined && currentIndex != oldCurrentIndex) {
          yield put({ type: 'updateState', payload: { currentIndex } })
          yield put.resolve({ type: 'getMusicProgress' })
        }

        requestWakeLock(); // 请求屏幕常亮
        window.audio.play().then(() => { }, () => { })

        yield put.resolve({ type: 'submitMusicProgress', payload: { audioOperate: 'Start' } })
      } else {
        yield put({ type: 'updateState', payload: { isOpen: status } })

        releaseWakeLock(); // 释放休眠锁
        window.audio.pause()
        // 删除本地保存音乐记录的定时器
        if (window[`localSaveMusicRecordIntervel${userId}`]) {
          clearInterval(window[`localSaveMusicRecordIntervel${userId}`])
        }
        // 提交当前的音乐播放进度
        if (needSubmitProgress) yield put.resolve({ type: 'submitMusicProgress' })

        canRequest && showMusicStatusMsg(intl, 'status')
      }

      yield put({ type: 'updateState', payload: { statusLoading: false } })
      yield put({ type: 'refreshPlanInfo' })
    },

    // 获取音乐进度
    *getMusicProgress({ payload: _ }, { call, put, select }) {
      const intl = IntlManager.MyIntl()

      yield put({ type: 'updateState', payload: { cacheLoading: true } })

      const { currentIndex, planInfo, subjectInfo, from, myMusicBlobList } = yield select((state) => state.musicplayer)
      const tracks = planInfo.iLs && planInfo.iLs.tracks
      const musicList = tracks ? tracks.map((item) => item.track && item.track.url) : []
      let presentTime = 0
      const UserId = subjectInfo.id
      const TrackCode = tracks && tracks[currentIndex] && tracks[currentIndex].code

      // // 判断当前数据是否掉后端api更新，如果没更新就掉后端api
      const localRecord = yield localForage.getItem(`currentPlanMusicRecord${UserId}`)

      if (localRecord && localRecord.isUpdateData === false) {
        if (from === 'trialSessionPlan') {
          const { iLs } = planInfo || {};
          const { curTrack } = iLs || {};
          const { duration } = curTrack || {};

          if (duration) {
            presentTime = convertTimeToSecond(duration)
          } else {
            presentTime = 0
          }

          // 更新缓存数据
          localRecord.isUpdateData = true
          localRecord.data = localRecord.data.map(item => {
            item.lastProgress = duration
            return item
          })

          // 把当前plan音乐记录存入缓存
          yield localForage.setItem(`currentPlanMusicRecord${UserId}`, localRecord)
        } else {
          let res
          const UserOfflinePlanId = from === 'groupClassDetail' ? planInfo.id : planInfo.userOfflineTrainingPlan.id
          const Num = parseInt(planInfo.num)

          if (from === 'groupClassDetail') {
            const params = {
              UserId,
              GroupTrainingPlanId: UserOfflinePlanId,
              Num,
              Platform: 'Web'
            }
            res = yield call(GetCourseProgress, params)
          } else {
            const params = {
              UserId,
              UserOfflinePlanId,
              Num,
              Platform: 'Web'
            }
            res = yield call(GetCurrentPlanMusicRecord, params)
          }

          if (res.response.ok) {
            const result = res.data
            const currentMusic = result.find(item => item.trackCode === TrackCode)
            if (currentMusic) {
              presentTime = convertTimeToSecond(currentMusic.lastProgress)
            } else {
              presentTime = 0
            }

            // 更新缓存数据
            localRecord.isUpdateData = true
            localRecord.data = localRecord.data.map(item => {
              const newItem = result.find(resultItem => resultItem.trackCode === item.trackCode)
              if (newItem) {
                item.lastProgress = newItem.lastProgress
                item.isFinished = newItem.isFinished
              }
              return item
            })

            // 把当前plan音乐记录存入缓存
            yield localForage.setItem(`currentPlanMusicRecord${UserId}`, localRecord)
          }
        }
      } else {
        localRecord && localRecord.data.forEach(item => {
          if (item.trackCode === TrackCode) {
            presentTime = convertTimeToSecond(item.lastProgress)
          }
        })
      }

      if (myMusicBlobList.length) {
        window.audio.src = myMusicBlobList[currentIndex];
        window.audio.currentTime = presentTime;

        yield put({ type: 'updateState', payload: { cacheLoading: false } })
        return;
      }

      const _myMusicBlobList = Array(musicList.length).fill(null);

      // 先缓存当前要播放的音乐
      yield fetch(
        musicList[currentIndex],
        {
          method: 'GET',
          responseType: 'blob',
        },
      )
      .then(response => response.blob())
      .then(myBlob => {
        var objectURL = URL.createObjectURL(myBlob);
        _myMusicBlobList[currentIndex] = objectURL;
      })

      window.audio.src = _myMusicBlobList[currentIndex]
      window.audio.currentTime = presentTime

      yield put({ type: 'updateState', payload: { cacheLoading: false } })

      // 在缓存其他待播放音乐
      yield [(musicList.map((item, index) => {
        if (index !== currentIndex) {
          return fetch(
            item,
            {
              method: 'GET',
              responseType: 'blob',
            },
          )
          .then(response => response.blob())
          .then(myBlob => {
            var objectURL = URL.createObjectURL(myBlob);
            _myMusicBlobList[index] = objectURL;
          }).catch(error => {});
        }
      }))]

      if (!_myMusicBlobList.every(item => item)) {
        yield [(musicList.map((item, index) => {
          if (index !== currentIndex) {
            return fetch(
              item,
              {
                method: 'GET',
                responseType: 'blob',
              },
            )
            .then(response => response.blob())
            .then(myBlob => {
              var objectURL = URL.createObjectURL(myBlob);
              _myMusicBlobList[index] = objectURL;
            }).catch(error => {});
          }
        }))]
      }

      if (!_myMusicBlobList.every(item => item)) {
        notification.destroy();
        showMusicStatusMsg(IntlManager.intl, 'audioCachingFailed');
        // message.error(intl('audioCachingFailed'));
      }

      yield put({ type: 'updateState', payload: { myMusicBlobList: _myMusicBlobList } })
    },

    // 刷新当前计划信息
    *refreshPlanInfo({ payload: _ }, { call, put, select }) {
      const { pathName, from } = yield select((state) => state.musicplayer)
      if (from !== 'groupClassDetail') {
        yield put({ type: fromObj[pathName] })
      }
    },

    *changeMusicByType({ payload: { type } }, { call, put, select }) {
      const { currentIndex, planInfo, myMusicBlobList } = yield select((state) => state.musicplayer)
      // const tracks = planInfo.iLs && planInfo.iLs.tracks
      // const musicList = tracks ? tracks.map((item) => item.track && item.track.url) : []
      if (!myMusicBlobList[currentIndex]) return message.error('文件无法正常播放')
      let index = {
        left: currentIndex - 1,
        right: currentIndex + 1,
      }[type]
      yield put({ type: 'changeMusicByIndex', payload: { index } })
    },

    *changeMusicByIndex({ payload: { index } }, { call, put, select, take }) {
      const { isOpen, planInfo } = yield select((state) => state.musicplayer)
      const { userOfflineTrainingPlan } = planInfo || {}
      // 判断此首音乐的前几首是否听了超过一半，如果没有弹toast
      // const localRecord = yield localForage.getItem(`currentPlanMusicRecord${userId}`)
      // let canListenNextMusic = true
      // if (userOfflineTrainingPlan && userOfflineTrainingPlan.isAudioRecommend) {
      //   localRecord && localRecord.data.forEach((localRecordItem, localRecordIndex) => {
      //     if (localRecordIndex < index && !localRecordItem.isFinished && convertTimeToSecond(localRecordItem.lastProgress) < convertTimeToSecond(localRecordItem.duration) / 2) {
      //       canListenNextMusic = false
      //     }
      //   })
      // }

      if (/*canListenNextMusic*/ true) {
        if (isOpen)
          // 暂停本次音乐，并且提交本次进度
          yield put.resolve({
            type: 'changeMusicStatus',
            payload: { status: false, canRequest: false },
          })
        // 打开下次音乐并获取进度
        yield put.resolve({
          type: 'changeMusicStatus',
          payload: { status: true, currentIndex: index },
        })
      } else {
        message.warning("请保证当前音乐与前面每首音乐听的时长超过一半")
      }
    },

    *closeGlobalMusicPlayer({ payload }, { call, put, select }) {
      notification.destroy()
      releaseWakeLock(); // 释放休眠锁
      const { isOpen, planInfo, subjectInfo } = yield select((state) => state.musicplayer)
      const userId = subjectInfo?.id
      localForage.removeItem(`prePlanInfo${userId}`)
      localForage.removeItem(`currentPlanMusicRecord${userId}`)
      if (isOpen) {
        window.audio.pause()
        if (window[`localSaveMusicRecordIntervel${userId}`]) {
          clearInterval(window[`localSaveMusicRecordIntervel${userId}`])
        }
        yield put({ type: 'updateState', payload: { isOpen: false } })
      }
      // 判断是其他页面还是播放暂停状态进入的
      if (planInfo && subjectInfo) {
        yield put.resolve({ type: 'submitMusicProgress', payload: { audioOperate: 'Stop' } })
      }
      yield put({ type: 'updateState', payload: { showGlobalMusicplayer: false, musicPlayerDrawerVisible: false, planInfo: null, subjectInfo: null, bluetoothCheckFlag: false, myMusicBlobList: [] } })
    },

    *closeOpenGlobalMusicPlayer({ payload }, { call, put, select }) {
      window.audio.pause()
      releaseWakeLock(); // 释放休眠锁
      const { subjectInfo } = yield select((state) => state.musicplayer)
      const userId = subjectInfo?.id
      localForage.removeItem(`prePlanInfo${userId}`)
      localForage.removeItem(`currentPlanMusicRecord${userId}`)
      if (window[`localSaveMusicRecordIntervel${userId}`]) {
        clearInterval(window[`localSaveMusicRecordIntervel${userId}`])
      }
      yield put({ type: 'updateState', payload: { showGlobalMusicplayer: false, isOpen: false, musicPlayerDrawerVisible: false, canPlay: false, myMusicBlobList: [] } })
    },

    *submitMusicProgress({ payload }, { call, put, select }) {
      // showMusicStatusMsg(IntlManager.intl, 'progress')

      const intl = IntlManager.MyIntl()

      const { currentUser } = yield select(state => state.user)
      const { current } = currentUser || {}
      const { roles = [] } = current || {}
      const isCoachOrIndependentCoach = roles.some(role => role.name === 'IndependentCoach' || role.name === 'Coach')

      let { currentTime, planInfo, subjectInfo, currentIndex, duration, from } = yield select(
        (state) => state.musicplayer
      )

      let audioOperate = ''
      if (payload) {
        currentTime = payload.oldCurrentTime || currentTime
        planInfo = payload.oldPlanInfo || planInfo
        currentIndex = payload.oldCurrentIndex != undefined ? payload.oldCurrentIndex : currentIndex
        audioOperate = payload.audioOperate || null
      }
      const tracks = planInfo.iLs && planInfo.iLs.tracks
      audioOperate = audioOperate
        ? audioOperate
        : parseInt(duration) == parseInt(currentTime)
          ? 'Finish'
          : 'Pause'

      const userId = subjectInfo.id
      const trackCode = tracks && tracks[currentIndex] && tracks[currentIndex].code

      let res, params
      if (from === 'trialSessionPlan') {
        const { id: trialPlanId } = planInfo || {}
        params = {
          trialPlanId,
          trackCode,
          progress: convertDuration(currentTime),
          platform: 'Web',
          audioOperate
        }

        res = yield call(PutTrialPlanProgress, params)
      } else {
        const userOfflinePlanId = from === 'groupClassDetail' ? planInfo.id : planInfo.userOfflineTrainingPlan.id
        const num = parseInt(planInfo.num)

        if (from === 'groupClassDetail') {
          params = {
            userId,
            trackCode,
            progress: convertDuration(currentTime),
            groupTrainingPlanId: userOfflinePlanId,
            num,
            audioOperate,
            platform: 'Web',
          }

          res = yield call(PostGroupMusicProgress, params)
        } else {
          params = {
            userId,
            trackCode,
            progress: convertDuration(currentTime),
            userOfflinePlanId,
            num,
            audioOperate,
            platform: 'Web',
          }

          res = yield call(PostMusicProgress, params)
        }
      }

      if (params.audioOperate == 'Finish') {
        const localRecord = yield localForage.getItem(`currentPlanMusicRecord${userId}`)
        const hasLocalRecord = localRecord && localRecord.data.find(item => item.trackCode === trackCode)

        if (hasLocalRecord) {
          hasLocalRecord.lastProgress = "00:00:00"
          hasLocalRecord.isFinished = true
          yield localForage.setItem(`currentPlanMusicRecord${userId}`, localRecord)
        }

        if (res.response.ok) {
          const { showReplayMessage, count, canListen, cannotListenReason } = res.data;

          if (currentIndex + 1 === tracks.length) {
            Modal.warning({
              title: cannotListenReason || intl(from === 'trialSessionPlan' ? 'automaticallyReplayed' : 'musicHasEnded'),
              content: '',
            })

            if (from === 'trialSessionPlan') {
              setTimeout(() => {
                Modal.destroyAll(); // 关闭所有模态框
                getDvaApp()._store.dispatch({
                  type: 'musicplayer/openTrialSessionMusicPlayerDrawer',
                  payload: {
                    planInfo,
                    from: 'trialSessionPlan',
                    subjectInfo,
                    autoPlay: true
                  },
                })
              }, 3000)
            }

            yield put({ type: 'closeGlobalMusicPlayer' })

            setTimeout(() => {
              notification.destroy()
            }, 1000)

            if (from !== 'groupClassDetail' && from !== 'trialSessionPlan') {
              getDvaApp()._store.dispatch({
                type: 'subjectOfflinePlan/loadMusicProgress',
                payload: {
                  UserOfflinePlanId: userOfflinePlanId,
                  UserId: userId,
                  Num: num
                }
              })
            }
          } else {
            yield put({ type: 'updateState', payload: { currentTime: 0 } })
          }

          // 最后一首提交判断
          // if (!canListen && (currentIndex + 1 === tracks.length)) {
          //   yield put({ type: 'closeOpenGlobalMusicPlayer' })

          //   getDvaApp()._store.dispatch({
          //     type: from === 'groupClassDetail' ?
          //     'groupOfflinePlanDetail/loadList' :
          //     'subjectOfflinePlan/loadList'
          //   })

          //   Modal.warning({
          //     title: cannotListenReason,
          //     content: '',
          //   })

          //   localStorage.setItem('showProgress', 'none')

          //   notification.destroy()
          //   return;
          // }

          // if (showReplayMessage) {
          //   yield put({
          //     type: 'changeMusicStatus',
          //     payload: { status: false, needSubmitProgress: !(tracks.length - 1 == currentIndex) },
          //   })
          //   notification.destroy()
          //   Modal.confirm({
          //     title: intl('musicHasEnd'),
          //     okText: intl('replay'),
          //     cancelText: intl('finish'),
          //     onOk: () => {
          //       window.dispatchEvent(window.parmEvent)
          //     },
          //     onCancel() { },
          //   })
          // }
        }
      }

      // localStorage.setItem('showProgress', 'none')
    },
  },

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },

  subscriptions: {
    setup({ dispatch, history }) {
      return history.listen((location) => {
        if (!hasGlobalMusicPlayerPathName.includes(location.pathname)) {
          dispatch({ type: 'closeGlobalMusicPlayer' })
        } else {
          if (Object.keys(fromObj).includes(location.pathname)) {
            dispatch({ type: 'updateState', payload: { pathName: location.pathname } })
          }
        }
      })
    },
  },
}

export default musicPlayer
