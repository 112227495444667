import React from 'react';
import { Drawer, Collapse, Spin, Popconfirm, Button, Tabs, message } from 'antd';
import FeedbackForm from 'components/FeedbackForm';
import FeedbackShow from 'components/FeedbackShow';
import GuardianFeedback from './GuardianFeedback';
import { EditOutlined } from '@ant-design/icons';
import { getDisabledValue } from 'utils/utils';
import { IntlManager } from 'utils/helper'
import styles from './index.less';

const { Panel } = Collapse;
const { TabPane } = Tabs;

class FeedbackDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingKey: [], // 当前提交的key
    };
  }

  getConfirmTitle = (type) => {
    const intl = IntlManager.MyIntl()

    switch (type) {
      case 'guardian':
        return (
          <div className={styles.popconfirmTitle}>
            <span className={styles.popconfirmTitleLeft}>
              {intl('sessionIsNotTakingPlace')}
            </span>
            <span className={styles.popconfirmTitleRight}>
              {intl('sessionWillBeUndo')}
            </span>
          </div>
        );
      case 'music':
        return <div>{intl('musicIsNotTakingPlace')}</div>;
      case 'activity':
        return <div>{intl('projectNotProgress')}</div>;
      default:
        return <div></div>;
    }
  };

  genExtra = (id, hasDone, type, disabled, item) => {
    const intl = IntlManager.MyIntl()

    const { loadingKey } = this.state;
    const {
      rocketProjectStatus,
      isBind = true,
      isCurrentNum,
      isLogout = false,
      hasOfflinePlanWritePermission,
      guardianCourseInfo,
      allData,
      loading,
      onGuardianNotDo,
      onNotDo,
      eidtItem,
    } = this.props;
    const {
      num: guardianNum,
      userOfflineTrainingPlan,
      hasDone: guardianHasDone,
    } = guardianCourseInfo;
    const { iLs, userOfflineTrainingPlan: otheruserOfflineTrainingPlan } =
      allData;
    const { tracks = [] } = iLs || {};
    const { audioType } = userOfflineTrainingPlan || {};
    const { canNext } = otheruserOfflineTrainingPlan || {};
    switch (hasDone) {
      case null:
        return (
          <div>
            {rocketProjectStatus &&
              isBind &&
              !isLogout &&
              hasOfflinePlanWritePermission ? (
              <Popconfirm
                placement="topRight"
                title={this.getConfirmTitle(type)}
                okText={intl("submit")}
                cancelText={intl("cancel")}
                disabled={disabled}
                onClick={(event) => {
                  event.stopPropagation();
                }}
                onCancel={(event) => {
                  event.stopPropagation();
                }}
                okButtonProps={{
                  danger: true,
                }}
                onConfirm={(event) => {
                  event.stopPropagation();
                  switch (type) {
                    case 'guardian':
                      loadingKey.push(id);
                      const params = {
                        id,
                        guardianNum,
                        notFinishAudios:
                          audioType === 'BrainAudio'
                            ? []
                            : tracks.map(
                              (item) => `${item.courseCode}-${item.code}`,
                            ),
                      };
                      onGuardianNotDo(params);
                      break;
                    case 'activity':
                      loadingKey.push(id);
                      onNotDo(id);
                      break;
                    default:
                      loadingKey.push(id);
                      onNotDo(id);
                  }
                  this.setState({ loadingKey });
                }}
              >
                <Button
                  danger
                  size="small"
                  loading={loading && loadingKey.indexOf(id) > -1}
                  disabled={disabled}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  {intl('notStarted')}
                </Button>
              </Popconfirm>
            ) : (
              <span>{intl('feedbackincompleted')}</span>
            )}
          </div>
        );
      case true:
        return (
          <span>
            <span>{intl('feedbacksubmitted')}</span>
            {!canNext &&
              isBind &&
              !isLogout &&
              isCurrentNum &&
              guardianHasDone != false &&
              hasOfflinePlanWritePermission && (
                <span
                  style={{ marginLeft: '10px', fontSize: '18px' }}
                  onClick={(event) => {
                    event.stopPropagation();
                    eidtItem(item, type);
                  }}
                >
                  <EditOutlined />
                </span>
              )}
          </span>
        );
      case false:
        return (
          <span>
            <span>{intl('notStarted')}</span>
            {!canNext &&
              isBind &&
              !isLogout &&
              isCurrentNum &&
              guardianHasDone != false &&
              hasOfflinePlanWritePermission && (
                <span
                  style={{ marginLeft: '10px', fontSize: '18px' }}
                  onClick={(event) => { }}
                >
                  <EditOutlined />
                </span>
              )}
          </span>
        );
      default:
        return null;
    }
  };

  genCategoryExtra = (childData, attendance) => {
    const intl = IntlManager.MyIntl()
    const { categoryInfo, isBind = true, isLogout = false, eidtItem, hasOfflinePlanWritePermission, isCurrentNum } = this.props

    if (categoryInfo && categoryInfo.length == 0) return <></>;

    const { userId } = childData || {}

    let item = {}
    if (categoryInfo.filter(item => item.userId == userId).length > 0) {
      item = categoryInfo.filter(item => item.userId == userId)[0]
    }

    if (Object.keys(item).length == 0) return <></>;

    const { hasDone } = item || {};

    if(attendance === 'Absent') {
      return (
          <span>{intl("absent")}</span>
      );
    }

    if (hasDone) {
      return (
        <span>
          <span>{intl('feedbacksubmitted')}</span>
          {
            isBind && !isLogout && hasOfflinePlanWritePermission && isCurrentNum &&
            <span
              style={{ marginLeft: '10px', fontSize: '18px' }}
              onClick={(event) => {
                event.stopPropagation()
                eidtItem(item, 'category')
              }}
            >
              <EditOutlined />
            </span>
          }
        </span>
      )
    }

    return null
  }

  genChildExtra = (hasDone, attendance, item) => {
    const intl = IntlManager.MyIntl()

    const {
      eidtItem,
      isBind = true,
      isLogout = false,
      hasOfflinePlanWritePermission,
      isCurrentNum,
      guardianCourseInfo
    } = this.props;

    const {
      hasDone: guardianHasDone,
    } = guardianCourseInfo;

    if(attendance === 'Absent') {
      return (
          <span>{intl("absent")}</span>
      );
    }
    if (hasDone === true && attendance === 'Attend') {
      return (
        <span>
          <span>{intl('feedbacksubmitted')}</span>
          {
            isBind &&
            !isLogout &&
            isCurrentNum &&
            guardianHasDone != false &&
            hasOfflinePlanWritePermission && (
              <span
                style={{ marginLeft: '10px', fontSize: '18px' }}
                onClick={(event) => {
                  event.stopPropagation();
                  eidtItem(item, 'child');
                }}
              >
                <EditOutlined />
              </span>
            )
          }
        </span>
      );
    } else if (hasDone === false && attendance === 'Attend') {
      return (
          <span>{intl('notStarted')}</span>
      );
    }
  };

  showFeedBackDetail = (item) => {
    const { loadingKey } = this.state;
    const {
      onSubmit,
      loading,
      rocketProjectStatus,
      isBind = true,
      isLogout = false,
      hasOfflinePlanWritePermission,
      guardianCourseInfo,
      allData,
      isCurrentNum
    } = this.props;
    const {
      feedbackProperties,
      trainingProgram,
      id,
      feedbacks,
      hasDone,
      isEdit,
      doneTime
    } = item;
    const { title, code, difficulty } = trainingProgram;
    const { userOfflineTrainingPlan: otheruserOfflineTrainingPlan } =
      allData || {};
    const { canNext } = otheruserOfflineTrainingPlan || {};
    const disabled = getDisabledValue({
      hasDone,
      rocketProjectStatus,
      isBind,
      isLogout,
      hasOfflinePlanWritePermission,
    });

    const activityNotDoIsDisabled = disabled;
    const {
      hasDone: guardianHasDone
    } = guardianCourseInfo;

    const that = this;
    const formProps = {
      id,
      loading,
      loadingKey,
      feedbackProperties,
      disabled,
      feedbacks,
      onOk(data) {
        loadingKey.push(id);
        that.setState({ loadingKey });
        onSubmit(data, trainingProgram.id, id, hasDone);
      },
    };
    const showProps = {
      feedbackProperties,
      feedbacks: feedbacks.map(item => {
        return {
          ...item,
          creationTime: doneTime,
          id
        }
      }),
    };

    return (
      <Panel
        key={id}
        header={`${title} ${difficulty || ''}`}
        extra={this.genExtra(
          id,
          hasDone,
          'activity',
          activityNotDoIsDisabled,
          item,
        )}
        collapsible={
          (guardianHasDone == false && disabled) ||
            (canNext && disabled) ||
            (!hasOfflinePlanWritePermission && disabled)
            ? 'disabled'
            : ''
        }
      >
        {isBind && !isLogout && isCurrentNum ? (
          item.hasOwnProperty('isEdit') ? (
            isEdit ? (
              <FeedbackForm {...formProps} />
            ) : (
              <FeedbackShow {...showProps} />
            )
          ) : hasDone == true ? (
            <FeedbackShow {...showProps} />
          ) : (
              <FeedbackForm {...formProps} />
            )
        ) : (
          <FeedbackShow {...showProps} />
        )}
      </Panel>
    );
  };

  showChildFeedBackDetail = (item) => {
    const intl = IntlManager.MyIntl();

    const { loadingKey } = this.state;
    const {
      onChildSubmit,
      loading,
      isBind = true,
      isLogout = false,
      hasOfflinePlanWritePermission,
      rocketProjectStatus,
      guardianCourseInfo,
      childEdit,
      isCurrentNum,
      activeKey,
      allData,
    } = this.props;
    const {
      attendance,
      hasDone,
      userName,
      id,
      userId,
      feedbackProperties = [],
      feedbacks = [],
      doneTime
    } = item;

    const {
      hasDone: guardianHasDone
    } = guardianCourseInfo;

    const that = this;
    const formProps = {
      id,
      loading,
      loadingKey,
      feedbackProperties,
      disabled: false,
      feedbacks,
      onOk(values) {
        loadingKey.push(id);
        that.setState({ loadingKey });

        onChildSubmit(values, id, hasDone);
      },
    };

    const disabled = getDisabledValue({
      hasDone,
      rocketProjectStatus,
      isBind,
      isLogout,
      hasOfflinePlanWritePermission,
    });

    const showProps = {
      feedbackProperties,
      feedbacks: feedbacks.map(item => {
        return {
          ...item,
          creationTime: doneTime,
          id
        }
      }),
    };

    const { planItems = [], nonePlaybook = [] } = allData;

    const categoryDisabled = planItems.some(item => item.hasDone == null) || nonePlaybook.some(item => item.hasDone == null)

    return (
      <>
        <h3>{userName}</h3>
        <Collapse
          activeKey={activeKey}
          onChange={(activeKey) =>
            this.props.onChangeCollapse(activeKey, 'child')
          }
        >
          <Panel
            key={id}
            header={intl('Personal Session Feedback')}
            extra={this.genChildExtra(hasDone, attendance, item)}
            collapsible={
              (guardianHasDone == false && disabled) ||
              (!hasOfflinePlanWritePermission && disabled) ||
              attendance === 'Absent'
                ? 'disabled'
                : ''
            }
          >
            {isBind && !isLogout && isCurrentNum ? (
              childEdit ? (
                <FeedbackForm {...formProps} />
              ) : hasDone == true ? (
                <FeedbackShow {...showProps} />
              ) : (
                <FeedbackForm {...formProps} />
              )
            ) : (
              <FeedbackShow {...showProps} />
            )}
          </Panel>
        </Collapse>
        <Collapse
          activeKey={activeKey}
          onChange={(activeKey) =>
            this.props.onChangeCollapse(activeKey, 'category', false, item)
          }
        >
          <Panel
            key={`category${userId}`}
            header={intl('Category Feedback')}
            collapsible={
              (guardianHasDone == false && disabled) ||
              (!hasOfflinePlanWritePermission && disabled) ||
              attendance === 'Absent' ||
              categoryDisabled
                ? 'disabled'
                : ''
            }
            extra={this.genCategoryExtra(item, attendance)}
            onClick={() => {
              if (categoryDisabled) {
                message.warning(intl('Please complete order'));
                return;
              }
            }}
          >
            {this.showCategoryFeedBackDetail(item)}
          </Panel>
        </Collapse>
        <p />
      </>
    );
  };

  showCategoryFeedBackDetail = (childData) => {
    const { onCategorySubmit, categoryInfo = [], loading, rocketProjectStatus, isBind = true, isLogout = false, hasOfflinePlanWritePermission, guardianCourseInfo, isCurrentNum } = this.props

    if (categoryInfo && categoryInfo.length == 0) return <></>;

    const { userId } = childData || {}

    let item = {}
    if (categoryInfo.filter(item => item.userId == userId).length > 0) {
      item = categoryInfo.filter(item => item.userId == userId)[0]
    }

    if (Object.keys(item).length == 0) return <></>;

    const { loadingKey } = this.state
    const { id, feedbackProperties = [], feedbacks = [], hasDone, isEdit } = item || {}
    const { num, id: groupTrainingPlanCourseId } = guardianCourseInfo || {}
    const disabled = getDisabledValue({ hasDone, rocketProjectStatus, isBind, isLogout, hasOfflinePlanWritePermission })

    const that = this
    const formProps = {
      id,
      loading,
      loadingKey,
      feedbackProperties,
      disabled,
      feedbacks,
      onOk(data) {
        loadingKey.push(id)
        that.setState({ loadingKey })
        const params = {
          feedbacks: data,
          hasDone,
          groupTrainingPlanCourseId,
          num,
          subjectId: userId,
        }
        onCategorySubmit(params)
      },
    }

    const showProps = {
      feedbackProperties,
      feedbacks: feedbacks.filter(item => item)
    }

    return (
      <>
        {isBind && !isLogout && isCurrentNum ? item.hasOwnProperty("isEdit") ?
          (
            isEdit ?
              <FeedbackForm {...formProps} />
              :
              <FeedbackShow {...showProps} />
          ) :
          (
            hasDone == true ?
              <FeedbackShow {...showProps} />
              :
              <FeedbackForm {...formProps} />
          )
          : <FeedbackShow {...showProps} />
        }
      </>
    )
  }

  onGuardianFeedback = (data) => {
    const { onGuardianFeedback, guardianCourseInfo } = this.props;
    const { loadingKey } = this.state;
    const { userOfflineTrainingPlanId } = data;
    const {
      hasDone: guardianHasDone
    } = guardianCourseInfo;
    loadingKey.push(userOfflineTrainingPlanId);
    this.setState({ loadingKey });
    onGuardianFeedback(data, guardianHasDone);
  };

  render() {
    const intl = IntlManager.MyIntl()

    const { loadingKey } = this.state;
    const {
      onClose,
      activeKey,
      activeTab,
      allData,
      visible,
      loading,
      drawLoading = false,
      guardianCourseInfo,
      hasOfflinePlanWritePermission,
      hasOfflinePlanReadPermission,
      rocketProjectStatus,
      isBind = true,
      isLogout = false,
      onChangeTabs,
      childFeedbackData
    } = this.props;
    if (allData === null || guardianCourseInfo == null) return null;
    const { num = 1, planItems = [], nonePlaybook = [] } = allData;
    const {
      hasDone: guardianHasDone,
      userOfflineTrainingPlan = {},
    } = guardianCourseInfo;

    const guardianIsDisabled = getDisabledValue({
      hasDone: guardianHasDone,
      rocketProjectStatus,
      isBind,
      isLogout,
      hasOfflinePlanWritePermission,
    });

    const guardianNotDoIsDisabled =
      guardianIsDisabled ||
      planItems.some((item) => item.hasDone !== null) ||
      nonePlaybook.some((item) => item.hasDone !== null) || 
      childFeedbackData.some((item) => item.hasDone !== null);

    const { feedbacks: guardianFeedbacks } = guardianCourseInfo || {};

    const hasGuardianFeedbacks =
      guardianFeedbacks &&
      Array.isArray(guardianFeedbacks) &&
      guardianFeedbacks.length > 0;

    const isShowGuardianFeedback = hasGuardianFeedbacks
      ? hasOfflinePlanReadPermission
      : hasOfflinePlanWritePermission;

    const guardianDrawerProps = {
      id: userOfflineTrainingPlan.id,
      loadingKey,
      allData: guardianCourseInfo,
      loading,
      guardianHasDone,
      onSubmit: this.onGuardianFeedback,
    };

    return (
      <Drawer
        title={intl('whichFeedback', { num })}
        width={720}
        open={visible}
        onClose={onClose}
      >
        <Tabs onChange={onChangeTabs} type="card" activeKey={activeTab}>
          <TabPane tab={intl('Overall Session Feedback')} key="class">
            <Spin spinning={drawLoading}>
              {visible && (
                <div>
                  {/* 训练项目反馈 */}
                  {planItems.length > 0 && (
                    <div className={styles.programTitle}>{intl("trainingActivities")}</div>
                  )}
                  <Collapse
                    activeKey={activeKey}
                    onChange={(activeKey) =>
                      this.props.onChangeCollapse(activeKey, 'activity')
                    }
                  >
                    {planItems.map((item) => {
                      return this.showFeedBackDetail(item);
                    })}
                  </Collapse>
                  {/* 附加活动反馈 */}
                  {nonePlaybook.length > 0 && (
                    <div className={styles.programTitle}>{intl("staticActivities")}</div>
                  )}
                  <Collapse
                    activeKey={activeKey}
                    onChange={this.props.onChangeCollapse}
                  >
                    {nonePlaybook.map((item) => {
                      return this.showFeedBackDetail(item);
                    })}
                  </Collapse>
                  {/* 课时整体反馈 */}
                  {isShowGuardianFeedback && (
                    <div className={styles.programTitle}>{intl("Overall Session Feedback")}</div>
                  )}
                  {isShowGuardianFeedback && (
                    <Collapse
                      activeKey={activeKey}
                      onChange={(activeKey) =>
                        this.props.onChangeCollapse(
                          activeKey,
                          'guardian',
                          guardianHasDone,
                        )
                      }
                    >
                      <Panel
                        key={userOfflineTrainingPlan.id}
                        header={intl("Overall Session Feedback")}
                        collapsible={guardianIsDisabled ? 'disabled' : ''}
                        extra={this.genExtra(
                          userOfflineTrainingPlan.id,
                          guardianHasDone,
                          'guardian',
                          guardianNotDoIsDisabled,
                        )}
                      >
                        <GuardianFeedback {...guardianDrawerProps} />
                      </Panel>
                    </Collapse>
                  )}
                </div>
              )}
            </Spin>
          </TabPane>
          <TabPane tab={intl("personalFeedback")} key="child">
            <Spin spinning={drawLoading}>
              {/* 个人反馈 */}
              {childFeedbackData.map((item) => {
                return this.showChildFeedBackDetail(item);
              })}
            </Spin>
          </TabPane>
        </Tabs>
      </Drawer>
    );
  }
}
export default FeedbackDrawer;
