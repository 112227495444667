import getRequest from 'utils/request';

const PostTrainingProgramSuggestionApi =
  '/api/rocketAdmin/trainingProgramSuggestion';
const PostTrainingProgramSuggestionLikeApi =
  '/api/rocketAdmin/trainingProgramSuggestion/{id}/like';
const GetCommentsListApi =
  '/api/rocketAdmin/trainingProgramSuggestion/commentsList';
const PostTrainingProgramSuggestionReplyApi =
  '/api/rocketAdmin/trainingProgramSuggestion/reply';
const PostGamificationListApi =
  '/api/rocketAdmin/trainingProgramSuggestion/gamificationList';
const PostTrainingProgramSuggestionReviewApi =
  '/api/rocketAdmin/trainingProgramSuggestion/review';
const GetProgramGamificationApi =
  '/api/rocketAdmin/trainingProgramSuggestion/programGamification';
const PutGamificationStatusApi =
  '/api/rocketAdmin/trainingProgramSuggestion/gamificationStatus';
const GetCommentsListExcelApi =
  '/api/rocketAdmin/trainingProgramSuggestion/commentsListExcel';
const PostGamificationListExcelApi =
  '/api/rocketAdmin/trainingProgramSuggestion/gamificationListExcel';

export async function GetCommentsListExcel(data) {
  return getRequest()(GetCommentsListExcelApi, {
    responseType: 'blob',
    method: 'GET',
    params: data,
  });
}

export async function PostGamificationListExcel(data) {
  return getRequest()(PostGamificationListExcelApi, {
    responseType: 'blob',
    method: 'POST',
    data,
  });
}

export async function PostTrainingProgramSuggestion(data) {
  return getRequest()(PostTrainingProgramSuggestionApi, {
    method: 'POST',
    data,
  });
}

export async function PutTrainingProgramSuggestion(data) {
  return getRequest()(PostTrainingProgramSuggestionApi, {
    method: 'PUT',
    data,
  });
}

export async function PostTrainingProgramSuggestionLike(data) {
  return getRequest()(
    PostTrainingProgramSuggestionLikeApi.replace('{id}', data.id),
    {
      method: 'POST',
      data,
    },
  );
}

export async function GetCommentsList(data) {
  return getRequest()(GetCommentsListApi, {
    method: 'GET',
    params: data,
  });
}

export async function PostTrainingProgramSuggestionReply(data) {
  return getRequest()(PostTrainingProgramSuggestionReplyApi, {
    method: 'POST',
    data,
  });
}

export async function PostGamificationList(data) {
  return getRequest()(PostGamificationListApi, {
    method: 'POST',
    data,
  });
}

export async function PostTrainingProgramSuggestionReview(data) {
  return getRequest()(PostTrainingProgramSuggestionReviewApi, {
    method: 'POST',
    data,
  });
}

export async function GetProgramGamification(data) {
  return getRequest()(GetProgramGamificationApi, {
    method: 'GET',
    params: data,
  });
}

export async function PutGamificationStatus(data) {
  return getRequest()(PutGamificationStatusApi, {
    method: 'PUT',
    data,
  });
}
