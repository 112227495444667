import React, { useState, useEffect } from 'react';
import { connect, useIntl } from 'umi';
import { Divider, message, List, Card, Space, Spin } from 'antd';
import { LikeOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
  GetProgramGamification,
  PostTrainingProgramSuggestionLike,
} from 'services/suggestion';

import moment from 'moment';

import styles from '../detail.less'

const IconText = ({ icon, text }) => (
  <Space size={4}>
    {icon}
    {text}
  </Space>
);

const GamificationCom = ({ currentUser, programInfo }) => {
  const intl = useIntl();

  const { staff } = currentUser || {};
  const { id: StaffId } = staff || {};

  const { id: TrainingProgramId } = programInfo || {};

  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);

  const initData = async () => {
    setLoading(true);

    const res = await GetProgramGamification({
      TrainingProgramId,
      SkipCount: 0,
      MaxResultCount: 999,
    });

    if (res.response.ok) {
      const { items = [] } = res.data || {};
      setListData(items);
    }

    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, [TrainingProgramId]);

  const onLike = async (item, isCreatorLiked) => {
    if (isCreatorLiked) {
      // 没有取消点赞功能
      return;
    }

    setLoading(true);

    const { id } = item || {};

    const res = await PostTrainingProgramSuggestionLike({ id });

    if (res.response.ok) {
      initData();
    }
  };

  return (
    listData.length > 0 && (
      <div style={{ marginTop: '20px' }} id="scrollableDiv">
        <Card
          size="small"
          bordered={false}
          title={
            <div className={styles.betweenItem} style={{ marginLeft: '-8px' }}>
              <Divider type="vertical" className={styles.dividerColor} />
              <span className={styles.infoTitle}>{intl.formatMessage({ id: 'Gamification Suggestions' })}</span>
            </div>
          }
        >
          <Spin spinning={loading}>
            <InfiniteScroll
              dataLength={listData.length}
              height={200}
              scrollableTarget="scrollableDiv"
              endMessage={
                <Divider plain>
                  <span style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: '10px' }}>
                    {intl.formatMessage({ id: 'No More Content' })}
                  </span>
                </Divider>
              }
            >
              <List
                itemLayout="horizontal"
                dataSource={listData}
                renderItem={(item) => {
                  const { content, creator, likes, creationTime } = item || {};
                  const isCreatorLiked = likes.some(
                    (like) => like.creatorId === StaffId,
                  );
                  return (
                    <List.Item
                      style={{ paddingRight: '10px' }}
                      extra={
                        <IconText
                          icon={
                            <LikeOutlined
                              onClick={() => onLike(item, isCreatorLiked)}
                              style={
                                isCreatorLiked
                                  ? { color: 'red' }
                                  : { cursor: 'pointer' }
                              }
                            />
                          }
                          text={likes.length}
                        />
                      }
                    >
                      <List.Item.Meta
                        title={<span>{content}</span>}
                        description={`${creator} ${intl.formatMessage({
                          id: 'on',
                        })} ${moment(creationTime).format('MM/DD/YYYY HH:mm')}`}
                      />
                    </List.Item>
                  );
                }}
              />
            </InfiniteScroll>
          </Spin>
        </Card>
      </div>
    )
  );
};

export default connect(({ user }) => ({ currentUser: user.currentUser }))(
  GamificationCom,
);
