import {
  GetRocketAssessment,
  GetAssessmentDetail,
  GetGetRocketAssessment,
  PostPostRocketAssessment,
  PostSendToGuardianApi,
  GetAssessmentReportApi,
  GetAssessmentQrCode,
  GetUserAssessment
} from 'services/rocketService/Assessment'
import { GetUserAssessmentTimesSettingRecords } from 'services/userConfiguration'
import { GetTenantGrant } from 'services/tenant'
import { message, Modal } from 'antd'
import { IntlManager } from 'utils/helper'
import { showRemainingTimesMsg, downloadQr } from 'utils/utils'
import { getDvaApp } from 'umi'
// import * as SurveyPDF from 'survey-pdf'
import { isJson } from 'cognitiveleap-core-us/utils/helper'
import moment from 'moment'

export default {
  namespace: 'subjectAssessment',

  state: {
    list: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    modalVisible: false,
    assessmentQrUrl: null,
    assessmentQrData: {},
    assessmentModalVisible: false,
    assessmentQrUrlModalVisible: false,
    transferList: [],
    sendToGuardianModalVisible: false,
    currentSendToGuardianRecord: null,
    currentAssessmentInfo: null,
    assessmentTimesSettingRecords: []
  },

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },

  effects: {
    // *downloadAssessmentPDF({ payload: id }, { call, put, take, select }) {
    //   const res = yield call(GetAssessmentDetail, id)
    //   if (res.response.ok) {
    //     const userAssessmentInfo = res.data
    //     const {
    //       questionnaireAnswer: { questionnaire, answer },
    //     } = userAssessmentInfo
    //     const questionnaireJson = isJson(questionnaire.jsonString)
    //       ? JSON.parse(questionnaire.jsonString)
    //       : null
    //     const answerJson = isJson(answer.answerContent) ? JSON.parse(answer.answerContent) : null

    //     if (questionnaireJson === null || answerJson === null) {
    //       message.error(`Error Display. UserAssessmentId: ${userAssessmentInfo.id} `)
    //     } else {
    //       const surveyPDF = new SurveyPDF.SurveyPDF(questionnaireJson)
    //       surveyPDF.data = answerJson
    //       surveyPDF.save()
    //     }
    //   }
    // },

    *loadList(_, { call, put, take, select }) {
      const { subjectInfo } = yield select(state => state.subjectDetail)
      const { pagination } = yield select(state => state.subjectAssessment)
      const currentPageIndex = pagination.current
      const currentPageSize = pagination.pageSize
      const query = {
        subjectId: subjectInfo.id,
        skipCount: (currentPageIndex - 1) * currentPageSize,
        maxResultCount: currentPageSize,
      }
      const resList = yield [
        call(GetRocketAssessment, query),
        call(GetUserAssessmentTimesSettingRecords, {
          userId: subjectInfo.id,
        })
      ]

      if (resList[0].response.ok) {
        const { items, totalCount } = resList[0].data
        const newPagination = {
          current: currentPageIndex,
          pageSize: currentPageSize,
          total: totalCount,
        }
        yield put({
          type: 'updateState',
          payload: { pagination: newPagination, list: items },
        })
      }

      if (resList[1].response.ok) {
        yield put({
          type: 'updateState',
          payload: { assessmentTimesSettingRecords: resList[1]?.data },
        })
      }
    },


    *changeTable({ payload: data }, { call, put, select }) {
      const { pagination: originPage } = yield select(state => state.subjectAssessment)
      const { pagination } = data
      yield put({ type: 'updateState', payload: { pagination: { ...originPage, ...pagination } } })
      yield put({ type: 'loadList' })
    },

    *loadTransferList(_, { call, put, take, select }) {
      const intl = IntlManager.MyIntl()
      const { subjectInfo } = yield select(state => state.subjectDetail)
      const query = {
        maxResultCount: 100,
        subjectId: subjectInfo.id
      }
      const res = yield call(GetGetRocketAssessment, query)
      if (res.response.ok) {
        const { items } = res.data
        yield put({ type: 'updateState', payload: { modalVisible: true, transferList: items } })
      } else {
        const { error } = res.data;
        Modal.warning({
          title: intl('reminder'),
          content: error.message,
          okText: intl('know')
        })
      }
    },

    *createAssessment({ payload: { keys, intl } }, { call, put, take, select }) {
      const { subjectInfo } = yield select(state => state.subjectDetail)
      yield put({ type: 'updateState', payload: { modalVisible: false } })

      const query = {
        subjectId: subjectInfo.id,
        assessmentIds: keys,
      }
      const res = yield call(PostPostRocketAssessment, query)
      if (res.response.ok) {
        yield put({ type: 'loadList' })
        message.success(intl.formatMessage({ id: 'success' }))
      } else {
        const { error } = res.data
        message.error(error && error.message)
      }
    },

    *sendToGuardian({ payload: { intl } }, { call, put, take, select }) {
      const { currentSendToGuardianRecord } = yield select(state => state.subjectAssessment)
      const parmas = {
        userAssessmentIds: [currentSendToGuardianRecord.id],
        send: true
      }
      const res = yield call(PostSendToGuardianApi, parmas)
      if (res.response.ok) {
        message.success(intl.formatMessage({ id: 'success' }))
        yield put({ type: 'loadList' })
        yield put({ type: 'updateState', payload: { sendToGuardianModalVisible: false } })
      } else {
        message.error(intl.formatMessage({ id: 'failed' }))
      }
    },

    *goSettings({ payload: { record } }, { call, put, take, select }) {
      yield put({ 
        type: 'updateState', 
        payload: { assessmentModalVisible: true, currentAssessmentInfo: record } 
      })

    },

    *getAssessmentReport({ payload: { record, intl, customReload } }, { call, put, take, select }) {
      const { id, assessment } = record || {}
      const { tagIds = [] } = assessment || {}
      const { subjectInfo } = yield select(state => state.subjectDetail)
      const { currentUser } = yield select(state => state.user)
      const recordRes = yield call(GetUserAssessmentTimesSettingRecords, {
        userId: subjectInfo.id,
      })

      if (recordRes.response.ok) {
        const selectItem = recordRes?.data?.find(record => {
          const { tag: { id } } = record || {}
          return tagIds.find(tag => tag === id)
        })
        const { remainingTimes, records } = selectItem || {}

        const tempRemainingTimes = tagIds.length > 0 ? remainingTimes+1 : 1
        // const tempRemainingTimes = tagIds.length > 0 ? (records && records.length === 0) ? null : remainingTimes : 1

        if (showRemainingTimesMsg(
          intl, 
          tempRemainingTimes, 
          currentUser, 
          'assessment', 
          'click', 
          () => getDvaApp()._store.dispatch({ 
            type: 'subjectAssessment/goSettings', 
            payload: {
              record: selectItem
            }})
          )) return

        const hide = message.loading(intl.formatMessage({ id: 'reportingWait' }), 0);
        const res = yield call(GetAssessmentReportApi, { userAssessmentId: id })
        setTimeout(hide, 0)
        if (res.response.ok) {
          if (customReload) {
            customReload()
          } else {
            yield put({ type: 'loadList' })
          }
        } else {
          const { error } = res.data || {}
          const { message: messageInfo } = error
          message.error(messageInfo)
        }
      } else {
        const { error } = recordRes.data || {}
        const { message: messageInfo } = error
        message.error(messageInfo)
      }
    },

    *getAssessmentQrCode({ payload: { record, intl, callback, closeLoading, from } }, { call, put, take, select }) {
      const { assessmentId, userId, id } = record || {};

      const res = yield call(GetAssessmentQrCode, { assessmentId, subjectId: userId, userAssessmentId: id });

      if (res.response.ok) {
        if (from === 'Retest') {
          const res2 = yield call(GetUserAssessment, { SubjectId: userId, AssessmentId: assessmentId });
          if (res2.response.ok) {
            yield put({
              type: 'updateState',
              payload: {
                assessmentQrUrl: `data:image/png;base64,${res.data}`,
                assessmentQrData: res2.data,
                assessmentQrUrlModalVisible: true
              }
            })
            if (callback) callback();
          }
        } else {
          const { assessmentName, creationTime } = record || {};
          yield put({
            type: 'updateState',
            payload: {
              assessmentQrUrl: `data:image/png;base64,${res.data}`,
              assessmentQrData: {
                ...record,
                userAssessment: { creationTime },
                title: assessmentName,
              },
              assessmentQrUrlModalVisible: true
            }
          })
          if (callback) callback();
        }
      } else {
        const { error } = res.data || {}
        const { message: messageInfo } = error || {}
        message.error(messageInfo)
      }

      if (closeLoading) closeLoading();
    },

    *downloadAssessmentQRPhoto({ payload: { intl } }, { call, put, take, select }) {
      const { assessmentQrUrl, assessmentQrData } = yield select(state => state.subjectAssessment)
      const { subjectInfo } = yield select(state => state.subjectDetail)
      const { name } =subjectInfo || {};
      // const { currentUser } = yield select(state => state.user)

      // const { changedTenant, name } = currentUser || {};
      // const { displayName } = changedTenant || {};
      const { title, userAssessment } = assessmentQrData || {};
      const { creationTime } = userAssessment || {};

      downloadQr({
        type: 'base64',
        data: assessmentQrUrl,
        title: title,
        subTitle: name,
        // description: displayName + '_' + name,
        descriptionSecend: `${intl.formatMessage({ id: 'assessmentExpiryDate' })}${moment(creationTime).add(30, 'd').format('YYYY-MM-DD')}`,
        downloadName: title,
        imgType: 'assessmentCode'
      })
    }
  },

  subscriptions: {
    setup({ dispatch, history }) {
      // Warning: [app.unmodel] subscription should return unlistener function, check these subscriptions setup
      return () => { }
    },
  },
}
