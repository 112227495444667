import React from 'react';
import { useIntl, getLocale } from 'umi';
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal';

const getDropdownOptions = (data) => {
  // 反馈多语言 匹配zh en key
  const language = getLocale() === 'zh-CN' ? 'zh' : 'en';

  const { options } = data.extraProperties[language] ?? data.extraProperties;

  return options.map((item) => {
    const { text, value } = item || {};
    return {
      Value: text,
      Id: value.toString(),
    };
  });
};

const EndPlanModal = ({
  from = 'oneToOne',
  visible,
  subjectInfo,
  onOk,
  onCancel,
  planInfo,
  currentClassItem,
}) => {
  const intl = useIntl();

  const { name } = subjectInfo || {};
  const { displayName } = planInfo || {};

  if (Object.keys(currentClassItem || {}).length === 0) {
    return <></>;
  }

  const { endPlanReasonQuestion } = currentClassItem || {};

  const contentMenu = {
    Properties: [
      {
        EditorType: 'CheckboxSelector',
        Value: [],
        Setting: {
          DropdownOptions: getDropdownOptions(endPlanReasonQuestion),
          Required: true,
        },
        ShowTitle: intl.formatMessage({ id: 'endPlanReason' }),
        FormKey: 'reason',
      },
    ],
  };

  const modalProps = {
    title: intl.formatMessage({ id: 'terminateThePlan' }),
    visible,
    content: contentMenu,
    onSubmitText: intl.formatMessage({ id: 'overPlan' }),
    onCancel() {
      onCancel();
    },
    onOk,
  };

  return <MagicFormModal {...modalProps} />;
};

export default EndPlanModal;
