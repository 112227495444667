import React from 'react';
import { Tabs } from 'antd';
import { useIntl } from 'umi';

import RecommendMusicList from './RecommendMusicList';
import PlanRecords from './PlanRecords';
import DifficultyModification from './DifficultyModification';

const RecordsTabs = (props) => {
  const { from } = props;

  const intl = useIntl();

  return (
    <Tabs
      defaultActiveKey={from !== 'group' ? '1' : '2'}
      destroyInactiveTabPane
    >
      {from !== 'group' && (
        <Tabs.TabPane
          tab={intl.formatMessage({ id: 'Renewal Records' })}
          key="1"
        >
          <RecommendMusicList {...props} />
        </Tabs.TabPane>
      )}
      <Tabs.TabPane tab={intl.formatMessage({ id: 'Plan Records' })} key="2">
        <PlanRecords {...props} />
      </Tabs.TabPane>
      <Tabs.TabPane
        tab={intl.formatMessage({ id: 'Difficulty Modification' })}
        key="3"
      >
        <DifficultyModification {...props} />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default RecordsTabs;
