import React, { useState, useEffect } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Space, Image } from 'antd';
import { useIntl } from 'umi';

import { GetCertificateList, GetTrainingList } from 'services/trainingSystem';

import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import AutoGenerationCertModal from 'components/AutoGenerationCertModal';
import TrainerFeedbackDrawer from './Modals/TrainerFeedbackDrawer';

import { downloadLongRangeImg } from 'cognitiveleap-core-us/utils/utils';

const timeFormat = 'MM/DD/YYYY HH:mm';

import moment from 'moment';

const isStatusActive = (startTime, endTime) => {
  return (
    moment().isSameOrAfter(moment(startTime)) &&
    moment().isSameOrBefore(moment(endTime))
  );
};

const CertificateTab = ({ roleType, userInfo, allowReloadTab, onChangeAllowReloadProgress }) => {
  const intl = useIntl();

  const { id } = userInfo || {};

  const [showList, setShowList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [moduleList, setModuleList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [feedbackDrawerVisible, setFeedbackDrawerVisible] = useState(false);
  const [currentItem, setCurrentItem] = useState({});
  // 翻页数据
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const { trainingModule } = currentItem || {};
  const { id: trainingModuleId } = trainingModule || {};

  const initData = async () => {
    setLoading(true);

    const res = await GetCertificateList({
      TrainerId: id,
      SkipCount: (pagination.current - 1) * pagination.pageSize,
      MaxResultCount: pagination.pageSize,
    });

    if (res.response.ok) {
      const { items = [], totalCount } = res.data || {};
      setShowList(items);
      setPagination({
        ...pagination,
        total: totalCount,
      });
    }

    setLoading(false);
  };

  const getModuleList = async () => {
    const res = await GetTrainingList({
      trainerId: id,
      TrainingStep: 'Certificate',
    });

    if (res.response.ok) {
      const data = res.data || [];
      setModuleList(data);
    }
  };

  useEffect(() => {
    initData();
  }, [pagination.current, pagination.pageSize, allowReloadTab]);

  useEffect(() => {
    if (roleType === 'admin') getModuleList();
  }, [id]);

  const listSupport = {
    credential: {
      showText: intl.formatMessage({ id: 'Certificate Name' }),
      showType: 'Text',
      render: (_, record) => {
        const { qualificationMedals = [] } = record || {};

        if (qualificationMedals.length) {
          const { displayName } = qualificationMedals[0] || {};
          return displayName;
        }

        return null;
      },
    },
    name: {
      showText: intl.formatMessage({ id: 'badge' }),
      showType: 'Text',
      render(text, record, index) {
        const { trainingModule, endTime, startTime } = record || {};
        const { qualificationMedals = [] } = trainingModule || {};
        const isActive = isStatusActive(startTime, endTime);
        //筛选已经上传的勋章
        const showPics = qualificationMedals.filter((item) =>
          isActive
            ? item.issuedStyle && item.issuedStyle.url
            : item.unissuedStyle && item.unissuedStyle.url,
        );
        return (
          <Space>
            {showPics.map((item) => {
              const url = isActive
                ? item.issuedStyle.url
                : item.unissuedStyle.url;
              return <Image key={item.id} src={url} width={80} />;
            })}
          </Space>
        );
      },
    },
    credentialsCode: {
      showText: intl.formatMessage({ id: 'Serial Number' }),
      showType: 'Text',
    },
    supervisorName: {
      showText: intl.formatMessage({ id: 'Supervisor' }),
      showType: 'Text',
    },
    date: {
      showText: intl.formatMessage({ id: 'Validity Period' }),
      showType: 'Text',
    },
    status: {
      showText: intl.formatMessage({ id: 'status' }),
      showType: 'Text',
      render: (text) => (text ? 'Active' : 'Inactive'),
    },
    creationTime: {
      showText: intl.formatMessage({ id: 'Issued Time' }),
      showType: 'Time',
      addtional: {
        format: timeFormat,
      },
    },
  };

  const autoGenerationCertModalProps = {
    visible: modalVisible,
    selectCertData: currentItem,
    selectItem: {
      ...userInfo,
      trainerId: id,
    },
    trainingModuleId,
    onCloseModal: () => {
      setModalVisible(false);
      initData();
      onChangeAllowReloadProgress && onChangeAllowReloadProgress();
    },
  };

  const trainerFeedbackDrawerProps = {
    visible: feedbackDrawerVisible,
    roleType,
    currentItem,
    selectCertData: currentItem,
    onClose: () => {
      setFeedbackDrawerVisible(false);
      initData();
      onChangeAllowReloadProgress && onChangeAllowReloadProgress();
    },
  };

  const onFillInFeedback = (record) => {
    setCurrentItem(record);
    setFeedbackDrawerVisible(true);
  };

  const tableProps = {
    loading,
    listData: showList.map((item) => {
      const { startTime, endTime } = item || {};
      return {
        ...item,
        date: `${moment(startTime).format('YYYY/MM/DD')}~${moment(
          endTime,
        ).format('YYYY/MM/DD')}`,
      };
    }),
    listSupport,
    pagination,
    onTableChange(page) {
      const { current, pageSize } = page;

      setPagination({
        ...pagination,
        current,
        pageSize,
      });
    },
    Actions: [
      {
        showText: 'Download Certificate',
        itemRender(record) {
          const { credential, feedback = [] } = record || {};
          const { url } = credential || {};
          if (url) {
            if (roleType === 'admin') {
              return (
                <a onClick={() => downloadLongRangeImg(credential)}>
                  {intl.formatMessage({ id: 'Download Certificate' })}
                </a>
              );
            }
            if (feedback.some((item) => item.finishTime !== null)) {
              return (
                <a onClick={() => downloadLongRangeImg(credential)}>
                  {intl.formatMessage({ id: 'Download Certificate' })}
                </a>
              );
            }
          }
          return null;
        },
      },
      {
        showText: 'View Feedback',
        itemRender(record) {
          const { feedback = [], trainerTrainingModuleStatus, trainingModule  } = record || {};
          const { questionnaires = [] } = trainingModule || {}

          if (feedback.some((item) => item.finishTime !== null)) {
            return (
              <a onClick={() => onFillInFeedback(record)}>
                {intl.formatMessage({ id: 'View Feedback' })}
              </a>
            );
          }

          return roleType === 'personal' && questionnaires.length > 0 &&
            trainerTrainingModuleStatus != 'Stopped' ? (
            <a onClick={() => onFillInFeedback(record)}>
              {intl.formatMessage({ id: 'Fill In Feedback' })}
            </a>
          ) : null;
        },
      },
    ],
  };

  const items = moduleList.map((item) => {
    const { id, trainingModule } = item || {};
    const { displayName } = trainingModule || {};
    return {
      key: id,
      label: (
        <a
          onClick={() => {
            setCurrentItem(item);
            setModalVisible(true);
          }}
        >
          {displayName}
        </a>
      ),
    };
  });

  return (
    <div>
      {roleType === 'admin' && Boolean(moduleList.length) && (
        <div style={{ textAlign: 'right' }}>
          <Dropdown
            menu={{
              items,
            }}
          >
            <Button type="link" onClick={() => {}}>
              + {intl.formatMessage({ id: 'Issue Certificate' })}{' '}
              <DownOutlined />
            </Button>
          </Dropdown>

          <p />
        </div>
      )}

      <MagicTable {...tableProps} />
      {modalVisible && (
        <AutoGenerationCertModal {...autoGenerationCertModalProps} />
      )}
      {feedbackDrawerVisible && (
        <TrainerFeedbackDrawer {...trainerFeedbackDrawerProps} />
      )}
    </div>
  );
};

export default CertificateTab;
