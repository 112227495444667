import React, { useState, useEffect } from 'react'
import { Drawer, Collapse, Spin, Popconfirm, Button, message } from 'antd'
import FeedbackForm from 'components/FeedbackForm'
import FeedbackShow from 'components/FeedbackShow'
import IlsMusicFeedBack from './IlsMusicFeedBack'
import GuardianFeedback from './GuardianFeedback'
import { EditOutlined } from '@ant-design/icons';
import { IntlManager } from 'utils/helper'
import { getDisabledValue } from 'utils/utils'
import styles from './index.less';

const { Panel } = Collapse

class FeedbackDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingKey: [],  // 当前提交的key
    }
  }

  getConfirmTitle = (type) => {
    const intl = IntlManager.MyIntl()

    switch (type) {
      case "guardian":
        return (
          <div className={styles.popconfirmTitle}>
            <span className={styles.popconfirmTitleLeft}>{intl('sessionIsNotTakingPlace')}</span>
            <span className={styles.popconfirmTitleRight}>{intl('sessionWillBeUndo')}</span>
          </div>
        )
      case "music":
        return <div>{intl('musicIsNotTakingPlace')}</div>
      case "activity":
        return <div>{intl('projectNotProgress')}</div>
      default:
        return <div></div>
    }
  }

  genExtra = (id, hasDone, type, disabled, item) => {
    const intl = IntlManager.MyIntl()
    const { loadingKey } = this.state
    const { rocketProjectStatus, isBind = true, activeKey, isLogout = false, hasOfflinePlanWritePermission, guardianCourseInfo, allData, loading, onGuardianNotDo, onMusicFeedBack, onNotDo, eidtItem, isCurrentNum } = this.props
    const { num: guardianNum, userOfflineTrainingPlan, hasDone: guardianHasDone } = guardianCourseInfo;
    const { iLs, userOfflineTrainingPlan: otheruserOfflineTrainingPlan } = allData
    const { tracks = [] } = iLs || {}
    const { audioType } = userOfflineTrainingPlan || {}
    const { canNext } = otheruserOfflineTrainingPlan || {}

    const userPlanId = userOfflineTrainingPlan.id

    switch (hasDone) {
      case null:
        return (
          <div>
            {
              rocketProjectStatus && isBind && !isLogout && hasOfflinePlanWritePermission ?
                <Popconfirm
                  placement="topRight"
                  title={this.getConfirmTitle(type)}
                  okText={intl('submit')}
                  cancelText={intl('cancel')}
                  disabled={disabled}
                  onClick={(event) => {
                    event.stopPropagation()
                  }}
                  onCancel={(event) => {
                    event.stopPropagation()
                  }}
                  okButtonProps={{
                    danger: true
                  }}
                  onConfirm={(event) => {
                    event.stopPropagation()
                    switch (type) {
                      case 'guardian':
                        loadingKey.push(id)
                        const params = {
                          id,
                          guardianNum,
                          notFinishAudios: audioType === "BrainAudio" ? [] : tracks.map(item => `${item.courseCode}-${item.code}`)
                        }
                        onGuardianNotDo(params);
                        break;
                      case 'music':
                        const parmas = {
                          userPlanId,
                          listenedList: [],
                          notListenedList: tracks.map(item => `${item.courseCode}-${item.code}`)
                        }
                        loadingKey.push('music')
                        onMusicFeedBack(parmas)
                        break
                      case 'activity':
                        loadingKey.push(id)
                        onNotDo(id)
                        break
                      default:
                        loadingKey.push(id)
                        onNotDo(id)
                    }
                    this.setState({ loadingKey })
                  }}
                >
                  <Button
                    danger
                    size="small"
                    loading={loading && loadingKey.indexOf(id) > -1}
                    disabled={disabled}
                    onClick={(event) => { event.stopPropagation() }}
                  >
                    {intl('notStarted')}
                  </Button>
                </Popconfirm>
                :
                <span>{intl('feedbacksubmitted')}</span>
            }
          </div>
        )
      case true:
        return (
          <span>
            <span>{intl('feedbacksubmitted')}</span>
            {
              !canNext && isBind && !isLogout && guardianHasDone != false && hasOfflinePlanWritePermission && isCurrentNum &&
              <span
                style={{ marginLeft: '10px', fontSize: '18px' }}
                onClick={(event) => {
                  event.stopPropagation()
                  eidtItem(item, type)
                }}
              >
                <EditOutlined />
              </span>
            }
          </span>
        )
      case false:
        return (
          <span>
            <span>{intl('notStarted')}</span>
            {
              !canNext && isBind && !isLogout && guardianHasDone != false && hasOfflinePlanWritePermission && isCurrentNum &&
              <span
                style={{ marginLeft: '10px', fontSize: '18px' }}
                onClick={(event) => { }}
              >
                <EditOutlined />
              </span>
            }
          </span>
        )
      default:
        return null
    }
  }

  genCategoryExtra = () => {
    const intl = IntlManager.MyIntl()
    const { categoryInfo, isBind = true, isLogout = false, eidtItem, hasOfflinePlanWritePermission, isCurrentNum } = this.props

    if (!(categoryInfo && Object.keys(categoryInfo).length > 0)) return <></>;

    const item = categoryInfo;

    const { hasDone } = item || {};

    if (hasDone) {
      return (
        <span>
          <span>{intl('feedbacksubmitted')}</span>
          {
            isBind && !isLogout && hasOfflinePlanWritePermission && isCurrentNum &&
            <span
              style={{ marginLeft: '10px', fontSize: '18px' }}
              onClick={(event) => {
                event.stopPropagation()
                eidtItem(item, 'category')
              }}
            >
              <EditOutlined />
            </span>
          }
        </span>
      )
    }

    return null
  }

  showFeedBackDetail = (item) => {
    const { loadingKey } = this.state
    const { onSubmit, loading, rocketProjectStatus, isBind = true, isLogout = false, hasOfflinePlanWritePermission, guardianCourseInfo, allData, isCurrentNum } = this.props
    const { feedbackProperties, trainingProgram, id, feedbacks, hasDone, isEdit } = item
    const { title, code, difficulty } = trainingProgram
    const { userOfflineTrainingPlan: otheruserOfflineTrainingPlan } = allData || {}
    const { canNext } = otheruserOfflineTrainingPlan || {}
    const disabled = getDisabledValue({ hasDone, rocketProjectStatus, isBind, isLogout, hasOfflinePlanWritePermission })

    const activityNotDoIsDisabled = disabled
    const { hasDone: guardianHasDone } = guardianCourseInfo;

    const that = this
    const formProps = {
      id,
      loading,
      loadingKey,
      feedbackProperties,
      disabled,
      feedbacks,
      onOk(data) {
        loadingKey.push(id)
        that.setState({ loadingKey })
        onSubmit(data, trainingProgram.id, id, hasDone)
      },
    }
    const showProps = {
      feedbackProperties,
      feedbacks
    }

    return (
      <Panel
        key={id}
        header={`${title} ${difficulty || ''}`}
        extra={this.genExtra(id, hasDone, 'activity', activityNotDoIsDisabled, item)}
        collapsible={(guardianHasDone == false && disabled) || (canNext && disabled) || (!hasOfflinePlanWritePermission && disabled) ? 'disabled' : ''}
      >
        {isBind && !isLogout && isCurrentNum ? item.hasOwnProperty("isEdit") ?
          (
            isEdit ?
              <FeedbackForm {...formProps} />
              :
              <FeedbackShow {...showProps} />
          ) :
          (
            hasDone == true ?
              <FeedbackShow {...showProps} /> 
              :
              <FeedbackForm {...formProps} /> 
          ) 
          : <FeedbackShow {...showProps} />
        }
      </Panel>
    )
  }

  showCategoryFeedBackDetail = () => {
    const { onCategorySubmit, categoryInfo = [], loading, rocketProjectStatus, isBind = true, isLogout = false, hasOfflinePlanWritePermission, guardianCourseInfo, isCurrentNum } = this.props

    if (!(categoryInfo && Object.keys(categoryInfo).length > 0)) return <></>;

    const item = categoryInfo

    const { loadingKey } = this.state
    const { id, feedbackProperties = [], feedbacks = [], hasDone, isEdit } = item
    const { num, userOfflineTrainingPlan } = guardianCourseInfo || {}
    const { id: userOfflineTrainingPlanId } = userOfflineTrainingPlan || {}
    const disabled = getDisabledValue({ hasDone, rocketProjectStatus, isBind, isLogout, hasOfflinePlanWritePermission })

    const that = this
    const formProps = {
      id,
      loading,
      loadingKey,
      feedbackProperties,
      disabled,
      feedbacks,
      onOk(data) {
        loadingKey.push(id)
        that.setState({ loadingKey })
        const params = {
          feedbacks: data,
          hasDone,
          userOfflineTrainingPlanId,
          num,
        }
        onCategorySubmit(params)
      },
    }

    const showProps = {
      feedbackProperties,
      feedbacks: feedbacks.filter(item => item)
    }

    return (
      <>
        {isBind && !isLogout && isCurrentNum ? item.hasOwnProperty("isEdit") ?
          (
            isEdit ?
              <FeedbackForm {...formProps} />
              :
              <FeedbackShow {...showProps} />
          ) :
          (
            hasDone == true ?
              <FeedbackShow {...showProps} />
              :
              <FeedbackForm {...formProps} />
          )
          : <FeedbackShow {...showProps} />
        }
      </>
    )
  }

  onGuardianFeedback = (data) => {
    const { onGuardianFeedback, guardianCourseInfo } = this.props
    const { loadingKey } = this.state
    const { userOfflineTrainingPlanId } = data
    const { hasDone: guardianHasDone, userOfflineTrainingPlan, audioRecordPunch } = guardianCourseInfo;
    loadingKey.push(userOfflineTrainingPlanId)
    this.setState({ loadingKey })
    onGuardianFeedback(data, guardianHasDone)
  }

  onMusicFeedBack = (data) => {
    const { loadingKey } = this.state
    const { onMusicFeedBack } = this.props
    loadingKey.push('music')
    this.setState({ loadingKey })
    onMusicFeedBack(data)
  }

  render() {
    const intl = IntlManager.MyIntl()
    const { loadingKey } = this.state;
    const { onClose, activeKey, allData, visible, loading, drawLoading = false, guardianCourseInfo, hasOfflinePlanWritePermission, hasOfflinePlanReadPermission, rocketProjectStatus, isBind = true, isLogout = false } = this.props
    if (allData === null || guardianCourseInfo == null) return null
    const { num = 1, planItems = [], nonePlaybook = [] } = allData
    const { hasDone: guardianHasDone, userOfflineTrainingPlan = {}, audioRecordPunch } = guardianCourseInfo;
    const { punched, listenedList } = audioRecordPunch || {}
    let musicHasDone = null
    if (punched === false) {
      musicHasDone = null
    }
    if (punched && listenedList.length === 0) {
      musicHasDone = false
    }
    if (punched && listenedList.length > 0) {
      musicHasDone = true
    }
    const { audioType, isAudioRecommend } = userOfflineTrainingPlan

    const guardianIsDisabled = getDisabledValue({ hasDone: guardianHasDone, rocketProjectStatus, isBind, isLogout, hasOfflinePlanWritePermission })

    const musicIsDisabled = ((!rocketProjectStatus || !isBind || isLogout || !hasOfflinePlanWritePermission) && musicHasDone === null)

    const guardianNotDoIsDisabled = guardianIsDisabled || musicHasDone !== null || planItems.some(item => item.hasDone !== null) || nonePlaybook.some(item => item.hasDone !== null)

    const categoryDisabled = planItems.some(item => item.hasDone == null) || nonePlaybook.some(item => item.hasDone == null)

    const musicIsNotDoIsDisabled = musicIsDisabled

    const { feedbacks: guardianFeedbacks } = guardianCourseInfo || {}

    const hasGuardianFeedbacks = guardianFeedbacks && Array.isArray(guardianFeedbacks) && guardianFeedbacks.length > 0

    const isShowGuardianFeedback = hasGuardianFeedbacks ? hasOfflinePlanReadPermission : hasOfflinePlanWritePermission

    const guardianDrawerProps = {
      id: userOfflineTrainingPlan.id,
      loadingKey,
      allData: guardianCourseInfo,
      loading,
      guardianHasDone,
      onSubmit: this.onGuardianFeedback
    }

    const musicFeedbackProps = {
      loading,
      loadingKey,
      allData,
      audioRecordPunch,
      musicHasDone,
      onSubmit: this.onMusicFeedBack
    }

    return (
      <Drawer
        title={intl('whichFeedback', { num })}
        width={720}
        open={visible}
        // maskClosable={false}
        onClose={onClose}
      >
        <Spin spinning={drawLoading}>
          {
            visible &&
            <div>
              {/* 训练项目反馈 */}
              {
                planItems.length > 0 &&
                <div className={styles.programTitle}>{intl("trainingActivities")}</div>
              }
              <Collapse
                activeKey={activeKey}
                onChange={(activeKey) => this.props.onChangeCollapse(activeKey, 'activity')}
              >
                {
                  planItems.map(item => {
                    return this.showFeedBackDetail(item)
                  })
                }
              </Collapse>
              {/* 附加活动反馈 */}
              {
                nonePlaybook.length > 0 &&
                <div className={styles.programTitle}>{intl("staticActivities")}</div>
              }
              <Collapse
                activeKey={activeKey}
                onChange={this.props.onChangeCollapse}
              >
                {
                  nonePlaybook.map(item => {
                    return this.showFeedBackDetail(item)
                  })
                }
              </Collapse>
              {/* 课时整体反馈 */}
              {isShowGuardianFeedback && <div className={styles.programTitle}>{intl('Overall Session Feedback')}</div>}
              {
                isShowGuardianFeedback &&
                <Collapse
                  activeKey={activeKey}
                  onChange={(activeKey) => this.props.onChangeCollapse(activeKey, 'guardian', guardianHasDone)}
                >
                  <Panel
                    key={userOfflineTrainingPlan.id}
                    header={intl("sessionFeedback")}
                    collapsible={guardianIsDisabled ? 'disabled' : ''}
                    extra={this.genExtra(userOfflineTrainingPlan.id, guardianHasDone, 'guardian', guardianNotDoIsDisabled)}
                  >
                    <GuardianFeedback {...guardianDrawerProps} />
                  </Panel>
                </Collapse>
              }
              {/* 项目类别反馈 */}
              <Collapse
                activeKey={activeKey}
                onChange={(activeKey) => this.props.onChangeCollapse(activeKey, 'category')}
              >
                <Panel
                  key={'categoryFeedback'}
                  header={intl("Category Feedback")}
                  collapsible={categoryDisabled ? 'disabled' : ''}
                  extra={this.genCategoryExtra()}
                  onClick={() => {
                    if (categoryDisabled) {
                      message.warning(intl("Please complete order"))
                      return
                    }
                  }}
                >
                  {this.showCategoryFeedBackDetail()}
                </Panel>
              </Collapse>
              {/* 音乐反馈 */}
              {
                audioType === "SenseAudio" && audioRecordPunch && isAudioRecommend &&
                <div className={styles.musicFeedback}>
                  <div className={styles.programTitle}>{intl("musicFeedback")}</div>
                  <Collapse
                    activeKey={activeKey}
                    onChange={(activeKey) => this.props.onChangeCollapse(activeKey, 'music')}
                  >
                    <Panel
                      header={intl("musicFeedback")}
                      key='music'
                      collapsible={musicIsDisabled ? 'disabled' : ''}
                      extra={this.genExtra('music', musicHasDone, 'music', musicIsNotDoIsDisabled)}
                    >
                      <IlsMusicFeedBack {...musicFeedbackProps} />
                    </Panel>
                  </Collapse>
                </div>
              }
            </div>
          }
        </Spin>
      </Drawer>
    )
  }
}
export default FeedbackDrawer
