import getRequest from 'utils/request'
import { getDvaApp } from 'umi'
import { showMusicStatusMsg } from 'utils/utils'
import { notification, message } from 'antd'
import { IntlManager } from 'utils/helper'

const postMusicProgressType = {
  Start: '/api/rocketAdmin/userOfflinePlan/startAudioTrack',
  Finish: '/api/rocketAdmin/userOfflinePlan/finishAudioTrack',
  Pause: '/api/rocketAdmin/userOfflinePlan/pauseAudioTrack',
  Stop: '/api/rocketAdmin/userOfflinePlan/stopAudioTrack'
}

const postGroupMusicProgessType = {
  Start: '/api/rocketAdmin/groupTrainingPlan/startAudioTrack',
  Finish: '/api/rocketAdmin/groupTrainingPlan/finishAudioTrack',
  Pause: '/api/rocketAdmin/groupTrainingPlan/pauseAudioTrack',
  Stop: '/api/rocketAdmin/groupTrainingPlan/stopAudioTrack'
}

const updateMusicProgress = '/api/rocketAdmin/userOfflinePlan/progressAudioTrack'

const getMusicProgress = '/api/rocketAdmin/userOfflinePlan/userAudioTrackProgress'
const getCurrentMusicIndex = '/api/rocketAdmin/userOfflinePlan/userCourseProgress'
const getCurrentPlanMusicRecordApi = '/api/rocketAdmin/userOfflinePlan/userCourseAudioTrackProgresses'

const getCourseProgressApi = '/api/rocketAdmin/groupTrainingPlan/userCourseAudioTrackProgresses'
const GetUserCourseProcessApi = '/api/rocketAdmin/groupTrainingPlan/userCourseProgress'
const putAsyncTrackApi = '/api/rocketAdmin/groupTrainingPlan/progressAudioTrack'

const GetTrialPlanMusicApi = '/api/rocketAdmin/trialPlan/musics/{trialPlanId}'
const PutTrialPlanProgressApi = '/api/rocketAdmin/trialPlan/progress'

// 音乐提交失败次数超过两次，自动暂停
let errTime = 0
let groupErrTime = 0

const stopMusicPlay = () => {
  getDvaApp()._store.dispatch({
    type: 'musicplayer/changeMusicStatus',
    payload: { status: false, needSubmitProgress: false, canRequest: false },
  })
  notification.destroy()
  showMusicStatusMsg(IntlManager.intl, 'error')
}

export async function GetCourseProgress(data) {
  return getRequest()(getCourseProgressApi, {
    method: 'GET',
    params: data,
  })
}

export async function GetTrialPlanMusic(data) {
  return getRequest()(GetTrialPlanMusicApi.replace('{trialPlanId}', data.trialPlanId), {
    method: 'GET',
    params: data,
  })
}

export async function PutTrialPlanProgress(data) {
  return getRequest()(PutTrialPlanProgressApi, {
    method: 'PUT',
    data,
  })
}

export async function GetUserCourseProcess(data) {
  return getRequest()(GetUserCourseProcessApi, {
    method: 'GET',
    params: data,
  })
}

// export async function PutAsyncTrack(data) {
//   return getRequest()(putAsyncTrackApi, {
//     method: 'PUT',
//     data
//   })
// }

export async function PutAsyncTrack(data, pauseMusic) {
  const { response } = await getRequest()(putAsyncTrackApi, {
    method: 'PUT',
    data
  })

  // 各种提交失败的情况
  if (!response?.ok) {
    groupErrTime++;
    if (groupErrTime <= 1) {
      showMusicStatusMsg(IntlManager.intl, 'networkDisconnected');
    }

    // message.error(formatMessage({ id: 'networkDisconnected' }))
  } else {
    groupErrTime = 0;
    notification.destroy();
  }
}

export async function GETMusicProgress(data) {
  return getRequest()(getMusicProgress, {
    method: 'GET',
    params: data,
  })
}

export async function PostMusicProgress(data) {
  const res = await getRequest()(postMusicProgressType[data.audioOperate], {
    method: 'POST',
    data,
  });

  const { response } = res || {};
  if (response?.ok && Boolean(errTime)) {
    // stopMusicPlay()
    errTime = 0;
  }

  return res;
}

export async function PostGroupMusicProgress(data) {
  const res = await getRequest()(postGroupMusicProgessType[data.audioOperate], {
    method: 'POST',
    data,
  })

  const { response } = res || {};
  if (response?.ok && Boolean(groupErrTime)) {
    // stopMusicPlay()
    groupErrTime = 0;
  }

  return res;
}

export async function UpdateMusicProgress(data) {
  const res = await getRequest()(updateMusicProgress, {
    method: 'PUT',
    data,
  })

  // 各种提交失败的情况
  const { response } = res || {};
  if (!response?.ok) {
    errTime++;
    if (errTime <= 1) {
      // getDvaApp()._store.dispatch({
      //   type: 'musicplayer/changeMusicStatus',
      //   payload: { status: false, needSubmitProgress: false, canRequest: false },
      // })

      // message.error(formatMessage({ id: 'networkDisconnected' }))
      showMusicStatusMsg(IntlManager.intl, 'networkDisconnected');
    }
  } else {
    errTime = 0;
    notification.destroy();
  }

  return res;
}

export async function GetCurrentMusicIndex(data) {
  return getRequest()(getCurrentMusicIndex, {
    method: 'GET',
    params: data,
  })
}

export async function GetCurrentPlanMusicRecord(data) {
  return getRequest()(getCurrentPlanMusicRecordApi, {
    method: 'GET',
    params: data,
  })
}