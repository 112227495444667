import React, { useState, useEffect } from 'react';
import { useIntl, connect } from 'umi';
import { Modal, Form, Button, message } from 'antd';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import {
  GetUserOfflineDifficultyModifyRecords,
  PutDifficultyModifyReason,
  GetReasonQuestion,
} from 'services/rocketService/Assessment';
import {
  GetGroupDifficultyModifyRecords,
  PutGroupDifficultyModifyRecords,
} from 'services/group';

import { showAttributeName, showAttributeDOM } from 'utils/feedback';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

const DifficultyModification = ({ activePlan, from, currentUser, allowReloadTab }) => {
  const intl = useIntl();

  const [form] = Form.useForm();

  const { staff } = currentUser || {};
  const { id: StaffId } = staff || {};

  const isFromGroup = from === 'group';

  const { id } = activePlan || {};

  const [listData, setListData] = useState([]);
  const [reasonQuestion, setReasonQuestion] = useState({});
  const [currentItem, setCurrentItem] = useState({});
  const [loading, setLoading] = useState({
    tableLoading: false,
    modalLoading: false,
  });
  const [visible, setVisible] = useState(false);

  // 翻页数据
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });

  const { type, extraProperties } = reasonQuestion || {};

  const initData = async () => {
    setLoading({
      ...loading,
      tableLoading: true,
    });
    const res = isFromGroup
      ? await GetGroupDifficultyModifyRecords({
          GroupTrainingPlanId: id,
          SkipCount: (pagination.current - 1) * pagination.pageSize,
          MaxResultCount: pagination.pageSize,
        })
      : await GetUserOfflineDifficultyModifyRecords({
          UserOfflineTrainingPlanId: id,
          SkipCount: (pagination.current - 1) * pagination.pageSize,
          MaxResultCount: pagination.pageSize,
        });

    if (res.response.ok) {
      const { totalCount, items = [] } = res.data || {};
      setListData(items);
      setPagination({
        ...pagination,
        total: totalCount,
      });
    } else {
      const { error } = res.data;
      message.error(error.message);
    }
    setLoading({
      ...loading,
      tableLoading: false,
    });
  };

  const initReasonQuestion = async () => {
    const res = await GetReasonQuestion();
    if (res.response.ok) {
      setReasonQuestion(res.data);
    }
  };

  useEffect(() => {
    if (id) initData();
  }, [pagination.current, pagination.pageSize, id, allowReloadTab]);

  useEffect(() => {
    initReasonQuestion();
  }, []);

  useEffect(() => {
    if (Object.keys(currentItem).length > 0) {
      const { reason } = currentItem;
      form.setFieldValue('reason', reason);
    }
  }, [currentItem]);

  const columns = {
    creationTime: {
      showText: intl.formatMessage({ id: 'Time' }),
      showType: 'Time',
      addtional: {
        format: 'MM/DD/YYYY HH:mm',
      },
    },
    num: {
      showText: intl.formatMessage({ id: 'session' }),
      showType: 'Text',
    },
    trainingCategory: {
      showText: intl.formatMessage({ id: 'Activity Type' }),
      showType: 'Text',
      render: (text) => {
        const { displayName } = text || {};
        return displayName;
      },
    },
    beforeTrainingProgram: {
      showText: intl.formatMessage({ id: 'Activity Name' }),
      showType: 'Text',
      render: (text) => {
        const { title } = text || {};
        return title;
      },
    },
    beforeDifficulty: {
      showText: intl.formatMessage({ id: 'LVBefore' }),
      showType: 'Text',
    },
    afterDifficulty: {
      showText: intl.formatMessage({ id: 'LVAfter' }),
      showType: 'Text',
    },
    reason: {
      showText: intl.formatMessage({ id: 'Reason' }),
      showType: 'Text',
      render: (text, record) => {
        const { reasonQuestion } = record || {};
        const { type } = reasonQuestion || {};
        return showAttributeName(type, text, {
          programFeedbackProperty: reasonQuestion,
        });
      },
    },
    creator: {
      showText: intl.formatMessage({ id: 'Operator' }),
      showType: 'Text',
    },
  };

  const props = {
    loading: loading.tableLoading,
    listData,
    listSupport: columns,
    pagination,
    onTableChange(page) {
      const { current, pageSize } = page;
      setPagination({
        ...pagination,
        current,
        pageSize,
      });
    },
    Actions: [
      {
        showText: intl.formatMessage({ id: 'Edit Reason' }),
        itemRender(record) {
          const { creatorId } = record || {};
          return creatorId === StaffId ? (
            <span key="trainingPrograms">
              <a
                onClick={(e) => {
                  e.preventDefault();
                  setVisible(true);
                  setCurrentItem(record);
                }}
              >
                <span>{intl.formatMessage({ id: 'Edit Reason' })}</span>
              </a>
            </span>
          ) : null;
        },
      },
    ],
  };

  const onFinish = async (values) => {
    setLoading({ ...loading, modalLoading: true });
    const { id } = currentItem || {};

    const params = {
      id,
      ...values,
    };

    const res = isFromGroup
      ? await PutGroupDifficultyModifyRecords(params)
      : await PutDifficultyModifyReason(params);

    if (res.response.ok) {
      initData();
      setVisible(false);
    } else {
      const { error } = res.data;
      message.error(error.message);
    }
    setLoading({ ...loading, modalLoading: false });
  };

  const onCancel = () => {
    setVisible(false);
    setCurrentItem({});
  };

  return (
    <div>
      <MagicTable {...props} />
      {Object.keys(reasonQuestion || {}).length > 0 && (
        <Modal
          width={780}
          title={intl.formatMessage({ id: 'Edit Reason' })}
          open={visible}
          onCancel={onCancel}
          footer={[
            <Button key="back" onClick={onCancel}>
              {intl.formatMessage({ id: 'cancel' })}
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={loading.modalLoading}
              onClick={() => form.submit()}
            >
              {intl.formatMessage({ id: 'confirm' })}
            </Button>,
          ]}
        >
          <Form
            form={form}
            name="adjustForm"
            {...formItemLayout}
            onFinish={onFinish}
          >
            <Form.Item
              name="reason"
              label={intl.formatMessage({ id: 'adjustReason' })}
              rules={[
                {
                  required: true,
                  message: `${intl.formatMessage({
                    id: 'adjustReason',
                  })} ${intl.formatMessage({ id: 'isRequired' })}`,
                },
              ]}
            >
              {showAttributeDOM(type, extraProperties)}
            </Form.Item>
          </Form>
        </Modal>
      )}
    </div>
  );
};

export default connect(({ user }) => ({ currentUser: user.currentUser }))(DifficultyModification);
