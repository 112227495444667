import { Divider } from 'antd';
import style from './index.less';

const CardHead = ({ title, extra = <></>, type = 'default' }) => {
  return (
    <div className={style.cardHead}>
      {type === 'default' ? (
        <div className={style.cardHeadTitle}>
          <span className={style.title}>{title}</span>
          <span className={style.cardExtra}>{extra}</span>
        </div>
      ) : (
        <div className={style.cardHeadTitle2}>
          <span className={style.title2}>{title}</span>
          <span className={style.cardExtra2}>{extra}</span>
        </div>
      )}

      <Divider className={style.cardHeadDivider} />
    </div>
  );
};

export default CardHead;
