import { useIntl } from 'umi';
import { Modal, Button, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const CheckEndPlanModal = ({ open, onCancel, onEndPlan, onEndClass }) => {
  const intl = useIntl();

  return (
    <Modal
      open={open}
      title=""
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onEndPlan}>
          {intl.formatMessage({ id: 'Continue to Terminate Plan' })}
        </Button>,
        <Button key="submit" type="primary" onClick={onEndClass}>
          {intl.formatMessage({ id: 'classEnd' })}
        </Button>,
      ]}
    >
      <div style={{ margin: '15px 0' }}>
        <Space size="middle">
          <ExclamationCircleOutlined
            style={{ fontSize: '24px', color: '#faad14' }}
          />
          <span
            style={{ fontSize: '16px', fontWeight: '500', lineHeight: '20px' }}
          >
            {intl.formatMessage({ id: 'Session records have already' })}
          </span>
        </Space>
      </div>
    </Modal>
  );
};

export default CheckEndPlanModal;
