import getRequest from 'utils/request'
import { getLocale } from 'umi'
import moment from 'moment'

import download from 'cognitiveleap-core-us/utils/download'


const GetRegenerateListApi = '/api/rocketAdmin/userAssessment/regenerateList/{userId}'
const GetSpecialReminderApi = '/api/rocketAdmin/userOfflinePlan/specialReminder/{userId}'
const EditAssessmentOrder = '/api/rocketAdmin/assessment/move/{assessmentId}'
const PutPublishStatus = '/api/rocketAdmin/assessment/contentStatus'
const GetReport = '/api/rocketAdmin/userAssessment/report/{userAssessmentId}'
const GetDetail = '/api/rocketAdmin/userAssessment/{id}'
const GetRocketAssessmentList = '/api/rocketAdmin/userAssessment'
const GetRocketAssessments = '/api/rocketAdmin/assessment'
const PostRocketAssessmentsApi = '/api/rocketAdmin/assessment/list'
const PostRocketAssessment = '/api/rocketAdmin/userAssessment'
const GetUserAssessmentList = '/api/rocketAdmin/userAssessment/userAssessmentsPaged'
const GetAssessmentListQrCode = '/api/rocketAdmin/QrCode/Assessment'
const GetSignDataApi = '/api/rocketAdmin/userAssessment/informedConsent'
const PostSendToGuardian = '/api/rocketAdmin/userAssessment/confirm'
const PostUserAssessmentsExcelApi = '/api/rocketAdmin/userAssessment/userAssessmentsExcel'
const GetAssessmentReport = '/api/rocketAdmin/userAssessment/obtainReport/{userAssessmentId}'
const GetAssessmentQrCodeApi = '/api/rocketAdmin/QrCode/UserAssessment';
const GetCustomPlanListApi = '/api/rocketAdmin/userOfflineTrainingPlanCustomized/customizedTrainingPlanList'
const PostCustomPlanApi = '/api/rocketAdmin/userOfflineTrainingPlanCustomized/customizedTrainingPlan/{userOfflineTrainingPlanId}'
const GetLastCustomPlanApi = '/api/rocketAdmin/userOfflineTrainingPlanCustomized/customizedPlan'
const GetUserAssessmentApi = '/api/rocketAdmin/userAssessment/userAssessment'
const GetAssessmentGrantTenantsListApi = '/api/rocketAdmin/assessment/grantTenants'
const GetOfflineTrainingPlanGrantTenantsListApi = '/api/rocketAdmin/offlineTrainingPlan/grantTenants'
const PutAssessmentBatchTenantsGrantApi = '/api/rocketAdmin/assessment/batchTenantGrant'
const PutOfflineTrainingPlanGrantTenantsListApi = '/api/rocketAdmin/offlineTrainingPlan/batchTenantGrant'
const GetUserOfflinePlanRecordsApi = '/api/rocketAdmin/userOfflinePlan/planRecords'
const GetUserOfflineDifficultyModifyRecordsApi = '/api/rocketAdmin/userOfflinePlan/difficultyModifyRecords'
const PutDifficultyModifyReasonApi = '/api/rocketAdmin/userOfflinePlan/difficultyModifyReason'
const GetReasonQuestionApi = '/api/rocketAdmin/feedbackProperties/modifyDifficultyReasonQuestion'

export async function GetReasonQuestion(data) {
  return getRequest()(GetReasonQuestionApi, {
    method: 'GET',
    params: data,
  })
}

export async function PutDifficultyModifyReason(data) {
  return getRequest()(PutDifficultyModifyReasonApi, {
    method: 'PUT',
    data,
  })
}

export async function GetUserOfflinePlanRecords(data) {
  return getRequest()(GetUserOfflinePlanRecordsApi, {
    method: 'GET',
    params: data,
  })
}

export async function GetUserOfflineDifficultyModifyRecords(data) {
  return getRequest()(GetUserOfflineDifficultyModifyRecordsApi, {
    method: 'GET',
    params: data,
  })
}

export async function GetAssessmentGrantTenantsList(data) {
  return getRequest()(GetAssessmentGrantTenantsListApi, {
    method: 'GET',
    params: data,
  })
}

export async function PutAssessmentBatchTenantsGrant(data) {
  return getRequest()(PutAssessmentBatchTenantsGrantApi, {
    method: 'PUT',
    data,
  })
}

export async function GetOfflineTrainingPlanGrantTenantsList(data) {
  return getRequest()(GetOfflineTrainingPlanGrantTenantsListApi, {
    method: 'GET',
    params: data,
  })
}

export async function PutOfflineTrainingPlanGrantTenantsList(data) {
  return getRequest()(PutOfflineTrainingPlanGrantTenantsListApi, {
    method: 'PUT',
    data,
  })
}


export async function GetUserAssessment(data) {
  return getRequest()(GetUserAssessmentApi, {
    method: 'GET',
    params: data,
  })
}

export async function GetCustomPlanList(data) {
  return getRequest()(GetCustomPlanListApi, {
    method: 'GET',
    params: data,
  })
}

export async function PostCustomPlan(data) {
  return getRequest()(PostCustomPlanApi.replace('{userOfflineTrainingPlanId}', data.userOfflineTrainingPlanId), {
    method: 'POST',
    data,
  })
}

export async function GetLastCustomPlan(data) {
  return getRequest()(GetLastCustomPlanApi, {
    method: 'GET',
    params: data,
  })
}

export async function GetAssessmentQrCode(data) {
  return getRequest()(GetAssessmentQrCodeApi, {
    method: 'GET',
    params: data,
  })
}

export async function PublishStatus(data) {
  return getRequest()(PutPublishStatus, {
    method: 'PUT',
    data,
  })
}

export async function PostUserAssessmentsExcel(data, tenantName = '') {
  return getRequest()(PostUserAssessmentsExcelApi, {
    reponseType: 'blob',
    method: 'POST',
    data: data,
  }).then(res => {
    const { response } = res
    if (response.ok) {
      const reportTitle = (getLocale() === 'zh-CN' ? '测试统计报告' : 'Test Statistics Report') + '-' + tenantName + moment().format('YYYY-MM-DD HH:mm:ss') + '.xlsx'
      response.blob().then(blob => {
        download(blob, reportTitle)
      })
    }
  })
}

export async function EditSortOrder(assessmentId, newSortOrder) {
  return getRequest()(EditAssessmentOrder.replace('{assessmentId}', assessmentId), {
    method: 'PUT',
    params: { newSortOrder },
  })
}

export async function GetAssessmentDetail(id) {
  return getRequest()(GetDetail.replace('{id}', id), {
    method: 'GET',
  })
}

export async function GetSignData(data) {
  return getRequest()(GetSignDataApi, {
    method: 'GET',
    params: data
  })
}


export async function GetRocketReport(userAssessmentId) {
  return getRequest()(GetReport.replace('{userAssessmentId}', userAssessmentId), {
    method: 'GET',
  })
}

export async function GetRocketAssessment(data) {
  return getRequest()(GetRocketAssessmentList, {
    method: 'GET',
    params: data,
  })
}

export async function GetGetRocketAssessment(data) {
  return getRequest()(GetRocketAssessments, {
    method: 'GET',
    params: data,
  })
}
export async function PostPostRocketAssessmentList(data) {
  return getRequest()(PostRocketAssessmentsApi, {
    method: 'POST',
    data,
  })
}

export async function PostPostRocketAssessment(data) {
  return getRequest()(PostRocketAssessment, {
    method: 'POST',
    data,
  })
}

export async function GetUserAssessmentListApi(data) {
  return getRequest()(GetUserAssessmentList, {
    method: 'POST',
    data
  })
}

export async function GetAssessmentListQrCodeApi(data) {
  return getRequest()(GetAssessmentListQrCode, {
    method: 'GET',
    params: data
  })
}

export async function PostSendToGuardianApi(data) {
  return getRequest()(PostSendToGuardian, {
    method: 'POST',
    data,
  })
}

export async function GetAssessmentReportApi(data) {
  return getRequest()(GetAssessmentReport.replace('{userAssessmentId}', data.userAssessmentId), {
    method: 'POST',
    data,
  })
}

export async function GetRegenerateList(data) {
  return getRequest()(GetRegenerateListApi.replace('{userId}', data.userId), {
    method: 'GET',
    params: data
  })
}

export async function GetSpecialReminder(data) {
  return getRequest()(GetSpecialReminderApi.replace('{userId}', data.userId), {
    method: 'GET',
    params: data
  })
}