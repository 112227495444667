import React, { useState, useEffect } from 'react';
import {
  message,
  Spin,
  Modal,
  Checkbox,
  Form,
  Radio,
  Row,
  Col,
  Button,
  InputNumber,
  Input,
  Space,
} from 'antd';
import { useIntl } from 'umi';
import { GetPlanItemDetail } from 'services/rocketService/UserOfflinePlan';
import { showAttributeDOM } from 'utils/feedback';

import styles from './index.less';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

// 比较等级
const compareLvl = (fromDifficulty, minDifficulty, maxDifficulty) => {
  const minNum = minDifficulty.replace(/[^\d]/g, ' ');
  const maxNum = maxDifficulty.replace(/[^\d]/g, ' ');
  if (fromDifficulty >= minNum && fromDifficulty <= maxNum) {
    return true;
  } else {
    return false;
  }
};

const getUseData = (fromDifficulty, from, item) => {
  if (from === 'OneToOne') {
    const {
      difficultyAdjusted,
      trainingProgramDetail: {
        title,
        id,
        categories,
        maxDifficulty,
        minDifficulty,
        difficulty,
        difficulty2,
      },
    } = item;

    const { displayName = '' } = categories[0] || {};

    const canChangeLevel = compareLvl(
      fromDifficulty,
      minDifficulty,
      maxDifficulty,
    );

    return {
      id,
      canChangeLevel,
      displayName,
      title,
      difficultyAdjusted,
      difficulty,
      difficulty2,
      maxDifficulty,
      minDifficulty,
    };
  } else {
    const {
      difficultyAdjusted,
      trainingProgram: {
        title,
        id,
        categories,
        maxDifficulty,
        minDifficulty,
        difficulty,
        difficulty2,
      },
    } = item;

    const { displayName = '' } = categories[0] || {};
    const canChangeLevel = compareLvl(
      fromDifficulty,
      minDifficulty,
      maxDifficulty,
    );

    return {
      id,
      canChangeLevel,
      displayName,
      title,
      difficultyAdjusted,
      difficulty,
      difficulty2,
      maxDifficulty,
      minDifficulty,
    };
  }
};

const AdjustLevelModal = ({
  visible,
  from = 'OneToOne',
  submitAdjustLevelLoading,
  currentItem = {},
  onOk,
  onCancel,
}) => {
  const intl = useIntl();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [adjustData, setAdjustData] = useState({});

  const {
    num,
    operate,
    planItems = [],
    courseItems = [],
    userOfflineTrainingPlan,
    difficultyAdjustReasonQuestion,
  } = adjustData;
  const { ilsVersion } = userOfflineTrainingPlan || {};

  const { type, extraProperties } = difficultyAdjustReasonQuestion || {};

  // operate && form.setFieldValue('operate', operate);

  const isVersion2 = ilsVersion === 'V4';

  const planItemsData = from === 'OneToOne' ? planItems : courseItems;

  const initOneToOneData = async () => {
    setLoading(true);

    const {
      num,
      userOfflineTrainingPlan: { id: userOfflinePlanId },
    } = currentItem;

    const params = {
      num,
      userOfflinePlanId,
    };
    const res = await GetPlanItemDetail(params);

    if (res.response.ok) {
      // const { operate } = res.data;
      // operate && form.setFieldValue('operate', operate);
      setAdjustData(res.data);
    } else {
      const {
        data: { error },
      } = res;
      message.error(error.message);
    }

    setLoading(false);
  };

  const initOneToMoreData = () => {
    setAdjustData(currentItem);
  };

  useEffect(() => {
    // OneToOne OneToMore
    from === 'OneToOne' ? initOneToOneData() : initOneToMoreData();
  }, []);

  const clearProgramIds = (fromDifficulty, programIds) => {
    // form.setFieldValue('programIds', null);
    const newProgramIds = programIds.filter((item) => {
      let _minDifficulty, _maxDifficulty;
      const { trainingProgramDetail, trainingProgram } = planItemsData.find(
        (item2) => {
          if (from === 'OneToOne') {
            return item2.trainingProgramDetail.id === item;
          } else {
            return item2.trainingProgram.id === item;
          }
        },
      );
      if (from === 'OneToOne') {
        const { minDifficulty, maxDifficulty } = trainingProgramDetail || {};
        _minDifficulty = minDifficulty;
        _maxDifficulty = maxDifficulty;
      } else {
        const { minDifficulty, maxDifficulty } = trainingProgram || {};
        _minDifficulty = minDifficulty;
        _maxDifficulty = maxDifficulty;
      }
      return compareLvl(fromDifficulty, _minDifficulty, _maxDifficulty);
    });
    form.setFieldValue('programIds', newProgramIds);
  };

  return (
    <Modal
      width={780}
      title={intl.formatMessage({ id: 'adjustNumDifficulty' }, { num })}
      open={visible}
      className={styles.adjustModal}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          {intl.formatMessage({ id: 'cancel' })}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={submitAdjustLevelLoading}
          onClick={() => form.submit()}
        >
          {intl.formatMessage({ id: 'confirm' })}
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form form={form} name="adjustForm" {...formItemLayout} onFinish={onOk}>
          {/* <Form.Item
            name="operate"
            label={intl.formatMessage({ id: 'Adjust Difficulty' })}
            rules={[
              {
                required: true,
                message: `${intl.formatMessage({
                  id: 'trainingActivities',
                })} ${intl.formatMessage({ id: 'isRequired' })}`,
              },
            ]}
            extra={
              <span style={{ color: '#FF6600' }}>
                {intl.formatMessage({ id: 'Only one difficulty' })}
              </span>
            }
          >
            <Radio.Group disabled={operate !== null}>
              <Radio.Button value="Increase">
                {intl.formatMessage({ id: 'Add One Difficulty' })}
              </Radio.Button>
              <Radio.Button value="Decrease">
                {intl.formatMessage({ id: 'Reduce One Difficulty' })}
              </Radio.Button>
            </Radio.Group>
          </Form.Item> */}

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.difficulty !== currentValues.difficulty
            }
          >
            {() => {
              const { difficulty: fromDifficulty, programIds = [] } =
                form.getFieldsValue(true) || {};

              programIds &&
                programIds.length > 0 &&
                clearProgramIds(fromDifficulty || 1, programIds);

              return (
                <Form.Item
                  name="programIds"
                  label={intl.formatMessage({ id: 'trainingActivities' })}
                  rules={[
                    {
                      required: true,
                      message: `${intl.formatMessage({
                        id: 'trainingActivities',
                      })} ${intl.formatMessage({ id: 'isRequired' })}`,
                    },
                  ]}
                >
                  <Checkbox.Group>
                    <Row>
                      {planItemsData.map((item) => {
                        const { hasDone } = item || {};
                        const {
                          id,
                          difficulty,
                          difficulty2,
                          maxDifficulty,
                          minDifficulty,
                          canChangeLevel,
                          displayName,
                          title,
                          difficultyAdjusted,
                        } = getUseData(fromDifficulty || 1, from, item);

                        const minNum = minDifficulty.replace(/[^\d]/g, ' ');
                        const maxNum = maxDifficulty.replace(/[^\d]/g, ' ');

                        return (
                          <Col span={24} key={id}>
                            <Checkbox
                              value={id}
                              style={{ lineHeight: '32px' }}
                              disabled={
                                !canChangeLevel || difficultyAdjusted || hasDone
                              }
                            >
                              {`${displayName} - ${title} - ${
                                isVersion2 ? difficulty2 : difficulty
                              }${canChangeLevel ? '' : ''}`}
                              <span
                                style={{
                                  fontSize: '12px',
                                  color: 'rgba(0, 0, 0, 0.25)',
                                  marginLeft: '8px',
                                }}
                              >
                                {hasDone &&
                                  intl.formatMessage({
                                    id: 'hasDoneNotAdjust',
                                  })}
                              </span>
                              {!(difficultyAdjusted || hasDone) &&
                                fromDifficulty && (
                                  <span
                                    style={{
                                      fontSize: '12px',
                                      color: 'rgba(0, 0, 0, 0.25)',
                                      marginLeft: '8px',
                                    }}
                                  >
                                    {fromDifficulty > maxNum &&
                                      intl.formatMessage({
                                        id: 'Max difficulty',
                                      })}
                                    {fromDifficulty < minNum &&
                                      intl.formatMessage({
                                        id: 'Minimum difficulty',
                                      })}
                                  </span>
                                )}
                            </Checkbox>
                          </Col>
                        );
                      })}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              );
            }}
          </Form.Item>

          <Form.Item
            name="difficulty"
            label={intl.formatMessage({ id: 'Adjust Difficulty To' })}
            rules={[
              {
                required: true,
                message: `${intl.formatMessage({
                  id: 'trainingActivities',
                })} ${intl.formatMessage({ id: 'isRequired' })}`,
              },
            ]}
            initialValue={1}
          >
            <InputNumber min={1} max={20} />
          </Form.Item>

          <Form.Item
            name="reason"
            label={intl.formatMessage({ id: 'adjustReason' })}
            rules={[
              {
                required: true,
                message: `${intl.formatMessage({
                  id: 'adjustReason',
                })} ${intl.formatMessage({ id: 'isRequired' })}`,
              },
            ]}
          >
            {showAttributeDOM(type, extraProperties)}
          </Form.Item>

          {/* <Form.Item
            colon={false}
            label={
              <ExclamationCircleOutlined
                style={{ fontSize: '20px', color: '#FF6600' }}
              />
            }
          >
            <span style={{ color: '#FF6600' }}>
              {intl.formatMessage({ id: 'If there is feedback' })}
            </span>
          </Form.Item> */}
        </Form>
      </Spin>
    </Modal>
  );
};

export default React.memo(AdjustLevelModal);
