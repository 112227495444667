import React, { useState, useEffect } from 'react';
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal';
import { useIntl } from 'umi';
import { deepCopy, showErrorMsg } from 'cognitiveleap-core-us/utils/utils';

import { UpdateGroup, AddGroup } from 'services/group';

const AddOrEditClassModal = (props) => {

  const intl = useIntl()

  const contentMenu = {
    Properties: [
      {
        EditorType: 'Input',
        ShowTitle: intl.formatMessage({ id: 'className'}),
        FormKey: 'name',
        AdditionalData: null,
        Value: null,
        Setting: {
          Required: true,
        },
        Description: intl.formatMessage({ id: 'classRule'}),
      },
      // {
      //   EditorType: 'Dropdown',
      //   Value: 'PreSchool',
      //   ShowTitle: intl.formatMessage({ id: 'stage'}),
      //   Setting: {
      //     DropdownOptions: [
      //       {
      //         Value: intl.formatMessage({ id: 'preschool'}),
      //         Id: 'PreSchool',
      //       },
      //       {
      //         Value: intl.formatMessage({ id: 'grade_teenage'}),
      //         Id: 'GradeOrTeenSchool',
      //       },
      //       {
      //         Value: intl.formatMessage({ id: 'GoldenAge'}),
      //         Id: 'GoldenAge',
      //       }
      //     ],
      //     Required: true,
      //   },
      //   FormKey: 'ageStage',
      //   Description: null,
      // },
      {
        EditorType: 'NumberInput',
        ShowTitle: intl.formatMessage({ id: 'maxChildren' }),
        FormKey: 'capacity',
        AdditionalData: null,
        Value: 1,
        Setting: {
          Required: true,
          Props: {
            min: 1,
            max: 20,
          },
        },
        Description: intl.formatMessage({ id: 'classDes'}),
      },
    ],
  };

  const {
    type = 'add',
    visible = false,
    editData = {},
    closeModal = () => {},
    reloadPage = () => {},
    assignCoach = () => {},
  } = props;

  const [modalContent, setModalContent] = useState(contentMenu);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    addOrEditClass();
  }, [editData]);

  const addOrEditClass = () => {
    if (type === 'edit') {
      const { name, ageStage, capacity, groupLinkUsers } = editData;

      let tempContent = deepCopy(contentMenu);
      tempContent.Properties[0].Value = name;
      // tempContent.Properties[1].Value = ageStage;
      tempContent.Properties[1].Value = capacity !== 0 ? capacity : 1;
      // 最少人数不得小于当前已有儿童人数
      tempContent.Properties[1].Setting = {
        ...tempContent.Properties[1].Setting,
        Props: {
          min:
            groupLinkUsers &&
            (groupLinkUsers.length !== 0 ? groupLinkUsers.length : 1),
          max: 20
        },
      };
      tempContent.Properties[1].Description = intl.formatMessage({ id: 'classRule2'});

      // tempContent.Properties[1].Setting = {
      //   ...tempContent.Properties[1].Setting,
      //   Disabled: true,
      // };

      setModalContent(tempContent);
    }
  };

  const addClassModalProps = {
    title: `${intl.formatMessage({ id: type === 'add' ? 'addClass' :  'editClass' })}`,
    visible,
    content: modalContent,
    loading,
    onSubmitText: intl.formatMessage({ id: 'sureAdd' }),
    onCancel() {
      setModalContent(contentMenu);
      closeModal();
    },
    async onOk(value) {
      setLoading(true);
      if (type === 'add') {
        const res = await AddGroup(value);
        if (res.response.ok) {
          // 如果是添加，添加成功后自动打开关联计划弹窗
          assignCoach(res.data);
        } else {
          showErrorMsg(res, intl);
        }
      } else {
        // delete value.ageStage;
        const res = await UpdateGroup({
          ...value,
          id: editData.id,
        });
        if (!res.response.ok) {
          showErrorMsg(res, intl);
        }
        setModalContent(contentMenu);
      }

      closeModal();
      reloadPage();
      setLoading(false);
    },
  };

  return (
    <div>
      <MagicFormModal {...addClassModalProps} />
    </div>
  );
};

export default AddOrEditClassModal;
