import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import { useIntl } from 'umi';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import { GetUserOfflinePlanRecords } from 'services/rocketService/Assessment';
import { GetGroupPlanRecords } from 'services/group';
import { showAttributeName } from 'utils/feedback';

const PlanRecords = ({ activePlan, from, allowReloadTab }) => {
  const intl = useIntl();

  const { id } = activePlan || {};

  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(false);
  // 翻页数据
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });

  const initData = async () => {
    setLoading(true);
    const res =
      from === 'group'
        ? await GetGroupPlanRecords({
            GroupTrainingPlanId: id,
            SkipCount: (pagination.current - 1) * pagination.pageSize,
            MaxResultCount: pagination.pageSize,
          })
        : await GetUserOfflinePlanRecords({
            UserOfflineTrainingPlanId: id,
            SkipCount: (pagination.current - 1) * pagination.pageSize,
            MaxResultCount: pagination.pageSize,
          });

    if (res.response.ok) {
      const { totalCount, items = [] } = res.data || {};
      setListData(items);
      setPagination({
        ...pagination,
        total: totalCount,
      });
    } else {
      const { error } = res.data;
      message.error(error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (id) initData();
  }, [pagination.current, pagination.pageSize, id, allowReloadTab]);

  const columns = {
    creationTime: {
      showText: intl.formatMessage({ id: 'Time' }),
      showType: 'Time',
      addtional: {
        format: 'MM/DD/YYYY HH:mm',
      },
    },
    num: {
      showText: intl.formatMessage({ id: 'session' }),
      showType: 'Text',
    },
    planEvent: {
      showText: intl.formatMessage({ id: 'Events' }),
      showType: 'Text',
      render: (text) => intl.formatMessage({ id: `Event${text}` })
    },
    reason: {
      showText: intl.formatMessage({ id: 'Reason' }),
      showType: 'Text',
      render: (text, record) => {
        const { reasonQuestion = {}, planEvent } = record || {};
        const { type } = reasonQuestion || {};
        return planEvent != 'Terminate'
          ? '-'
          : showAttributeName(type, text, {
              programFeedbackProperty: reasonQuestion,
            });
      },
    },
    creator: {
      showText: intl.formatMessage({ id: 'Operator' }),
      showType: 'Text',
    },
  };

  const props = {
    loading,
    listData,
    listSupport: columns,
    pagination,
    onTableChange(page) {
      const { current, pageSize } = page;
      setPagination({
        ...pagination,
        current,
        pageSize,
      });
    },
  };

  return (
    <div style={{ whiteSpace: 'pre-line' }}>
      <MagicTable {...props} />
    </div>
  );
};

export default PlanRecords;
